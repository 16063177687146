import { format, utcToZonedTime } from "date-fns-tz";

export const RemoveTilde = (string) => {
    const ValidateVocales = { 'á': 'a', 'Á': 'A', 'é': 'e', 'É': 'E', 'í': 'i', 'Í': 'I', 'ó': 'o', 'Ó': 'O', 'ú': 'u', 'Ú': 'U' };
    for (let vocalTilde in ValidateVocales) {
        const vocal = ValidateVocales[vocalTilde];
        const expresionRegular = new RegExp(vocalTilde, 'g');
        string = string.replace(expresionRegular, vocal);
    }
    return string;
}

export const convertDate = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay + 1), 'yyyy-MM-dd')
    }
    return date;
}

export const convertDate2 = (date) => {
    if (date) {
        const timeZone = "Europe/Madrid";
        const zonedDate = utcToZonedTime(date, timeZone);
        let day = new Date(zonedDate)
        let numberDay = day.getDate()
        let month = new Date(zonedDate)
        let numberMonth = month.getMonth()
        let year = new Date(zonedDate)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay), 'yyyy-MM-dd', { timeZone: "Europe/Madrid" })
    }
    return date;
}

export const convertDate3 = (date) => {
    if (date) {
        let day = new Date(date)
        let numberDay = day.getDate()
        let month = new Date(date)
        let numberMonth = month.getMonth()
        let year = new Date(date)
        let numberYear = year.getFullYear()
        return format(new Date(numberYear, numberMonth, numberDay - 1), 'yyyy-MM-dd')
    }
    return date;
}