import { useNavigate } from "react-router-dom"
import { createContext, useState } from "react"
import config from '../../settings/config'


const AuthProvider = ({children}) => {
    const InitialUser = JSON.parse(localStorage.getItem('hoshii_user'))
    const InitialAccount = JSON.parse(localStorage.getItem('hoshii_account'))
    let navigate = useNavigate()
    // let location = useLocation()
    const [user, setUser] = useState(InitialUser || null)
    const [account, setAccount] = useState(InitialAccount || null)

    // console.log(user)
    const verifyToken = async () => {
        // console.log(user)
        try {
            if(user){
                const fetchtoken = await fetch(`${config.urlServer}/api/token/verify-token`,
                {
                    headers: {
                        'Authorization': `Bearer ${user.accessToken}`
                    }
                })
                if(!fetchtoken.ok){
                    logout()
                }
                const data = await fetchtoken.json()
                // console.log(data)
                if(data.code !== '200') {
                    logout()
                }
                let response = {
                    code:data.code,
                    msg: data.msg
                }
                return response
            }else{
                navigate('/login')
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const loggin = (user) => {
        const NewUser = JSON.stringify(user)
        setUser(user)
        localStorage.setItem('hoshii_user',NewUser)
        navigate('/')
    }

    const register = (user) => {
        const NewUser = JSON.stringify(user)
        setUser(user)
        localStorage.setItem('hoshii_user',NewUser)
    }

    const isLogged = (user) => {
        if(!user || user === null || user === null){
            navigate('/welcome')
        }
    }
    
    const logout = () => {
        sessionStorage.clear()
        localStorage.removeItem("hoshii_user")
        localStorage.removeItem("hoshii_account")
        setUser(null)
        navigate('/login')
    }

    const saveAccount = (account) => {
        const NewAccount = JSON.stringify(account)
        setAccount(account)
        localStorage.setItem('hoshii_account',NewAccount)
    }

    const contextValue = {
        user,
        account,
        loggin,
        isLogged,
        setUser,
        logout,
        register,
        verifyToken,
        saveAccount
    }
    return <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
}

export const AuthContext = createContext()
export default AuthProvider