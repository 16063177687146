import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { motion, useMotionValue } from "framer-motion";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

//Services
// import useAuth from "../../context/auth/useAuth";

// const cardMediaList = [
//   "https://i.pinimg.com/564x/21/4f/a6/214fa6eb6dad4da753ff2b046998d0c5.jpg",
//   "https://i.pinimg.com/564x/1b/78/33/1b7833292b7f0b072c671f3d90c6d1c4.jpg",
//   "https://i.pinimg.com/564x/bf/da/bb/bfdabb23800981082128df616a457054.jpg",
// ];

const TrendingSwipeCard = ({card, likeCard, dislikeCard}) => {
  const x = useMotionValue(0);
  // console.log(card)

  const [mediaCount, setMediaCount] = useState(0);
  const [cardInterface, setCardInterface] = useState(true)
  const [cardMediaList, setCardMediaList] = useState([]);

  const ChangeMedia = () => {
    if (cardInterface) {
      if (mediaCount < card.images.length - 1) {
        setMediaCount(mediaCount + 1);
      } else if (mediaCount === card.images.length - 1) {
        setMediaCount(0);
      }
    }
  };

  // const { user } = useAuth();
  // const token = !user?.accessToken ? null : user.accessToken;

  console.log(card)

  const [cardRotation, setCardRotation] = useState("");

  const [choiceDirection, setChoiceDirection] = useState("");

  return (
    <motion.div
      drag="x"
      style={{ x }}
      dragSnapToOrigin
      whileDrag={{ rotate: cardRotation === "right" ? "10deg" : "-10deg" }}
      onDragEnd={(e) => {
        const direction =
          (e.target.getBoundingClientRect().left +
            e.target.getBoundingClientRect().right) /
          2;

        if (direction > window.innerWidth / 2 + window.innerWidth * 0.1) {
          likeCard();
        }
        if (direction < window.innerWidth / 2 - window.innerWidth * 0.1) {
          dislikeCard();
        }

        setChoiceDirection("");
      }}
      onDrag={(e) => {
        const direction =
          (e.target.getBoundingClientRect().left +
            e.target.getBoundingClientRect().right) /
          2;

        if (direction > window.innerWidth / 2) {
          setCardRotation("right");
          setChoiceDirection("right");
        }
        if (direction < window.innerWidth / 2) {
          setCardRotation("left");
          setChoiceDirection("left");
        }
      }}
    >
      <Box
        sx={{
          position: "relative"
        }}
      >
        <Box
          // "url(https://i.pinimg.com/564x/64/ce/e4/64cee42d214b916346af569579b7ad3b.jpg)"
          component={"div"}
          sx={{
            width: { xs: "98vw", md: 400 },
            height: { xs: "80vh", md: 600 },
            borderRadius: { xs: 2, md: 5 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            boxShadow: "10px 10px 53px -14px rgba(0,0,0,0.75)",
            backgroundImage: `url(${card.images.length > 0? card.images[mediaCount].url : card.cardImg})`,
            bgcolor: "white",
            // backgroundRepeat: "no-repeat",
            // backgroundAttachment: "fixed",
            // backgroundPosition: "center",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          onClick={() => {
            ChangeMedia();
          }}
        />
        <Box
          sx={{
            width: { xs: "98vw", md: "100%" },
            borderRadius: { xs: "2vw 2vw 0 0", md: "1vw 1vw 0 0" },
            height: 65,
            position: "absolute",
            top: 0,
            // background: "rgb(255,255,255)",
            background:
              "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.6587885154061625) 29%, rgba(0,0,0,0.9052871148459384) 83%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            left: "50%",
            transform: "translateX(-50%)",
            opacity: cardInterface ? 1 : 0,
            transition: "0.3s ease"
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: { xs: "98vw", md: "100%" },
            }}
          >
            <Box
              component={"div"}
              sx={{
                width: 45,
                height: 45,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundImage: `url(${card?.accountId ? card.accountId.pic_profile : "https://res.cloudinary.com/dujeh3ms8/image/upload/v1681996179/chat-app-socket-test/vqueqktx6hcplgdmqcnz.png"})`,
                borderRadius: "50%",
                boxShadow: "0px 10px 50px 3px rgba(0,0,0,0.75)",
                ml: 1,
              }}
            />
            <Box
              sx={{
                width: "66%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "95%",
                  color: "white",
                }}
              >
                {card?.title}
              </Typography>
            </Box>
            {/* <Box  sx={{
          width: "0.1vw",
          height: "80%",
          bgcolor: "white"
        }}/> */}
            <MoreHorizIcon
              sx={{ color: "white", ml: 2.5, cursor: "pointer" }}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexWrap: "no-wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              mt: 1,
            }}
          >
            {card.images.map((tag, index) => (
              <Box
              key={index}
                sx={{
                  width: "100%",
                  height: 3,
                  bgcolor: index === mediaCount ? "#8B4DBA" : "white",
                  borderRadius: 10,
                  mx: 1,
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
};

export default TrendingSwipeCard;
