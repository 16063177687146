import React from "react";
import Header from "../components/Header";
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import io from "socket.io-client";

//Context
import useAuth from "../context/auth/useAuth";
import useChatContext from "../context/chat/useChatContext";

//Services
import ChatService from "../services/ChatService";

//icons
import ChatContent from "../components/chats/ChatContent";
import ChatMessages from "../components/chats/ChatMessages";
import AccountHistories from "../components/chats/AccountHistories";

//waves
import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
// import useNotyContext from "../context/notification/useNotyContext";
import ConfigServer from "../settings/config";

const ENDPOINT = ConfigServer.urlServer;

// const ENDPOINT = "https://qa.backend.hoshii.co";
// const ENDPOINT = "https://backend.hoshii.co";
// const ENDPOINT = "http://localhost:4000";

let socket, selectedChatCompare;

const Chats = () => {
  const {
    selectionChats,
    selectionSelectedChat,
    selectedChat,
    chats,
    removeSelectedChat,
  } = useChatContext();
  const { user, account } = useAuth();
  // const { notification, addNoty } = useNotyContext()

  const [load, setLoad] = React.useState(false);
  const [matches, setMatches] = React.useState([]);
  const [messages, setMessages] = React.useState([]);
  const [currentMessage, setCurrentMessage] = React.useState("");
  const [openHistoryCardsModal, setOpenHistoryCardsModal] =
    React.useState(false);
  const [selectedAccount, setSelectedAccount] = React.useState(null);

  const [socketConnected, setSocketConnected] = React.useState(false);
  const [tyPing, setTyPing] = React.useState(false);
  const [isTyPing, setIsTyPing] = React.useState(false);

  const token = !user?.accessToken ? null : user.accessToken;

  const openHistoryAccount = (account) => {
    setOpenHistoryCardsModal(true);
    setSelectedAccount(account);
  };

  React.useEffect(() => {
    socket = io(ENDPOINT);
    socket.emit("setup", account);
    socket.on("connected", () => {
      console.log("Receivedd 'connected' event");
      setSocketConnected(true);
    });

    socket.on("typing", () => {
      console.log("Received 'typing' event");
      setIsTyPing(true);
    });

    socket.on("stop typing", () => {
      console.log("Received 'stop typing' event");
      setIsTyPing(false);
    });
    // Cleanup function
    return () => {
      socket.off("connected");
      socket.off("typing");
      socket.off("stop typing");
      socket.disconnect();
    };
  }, [account]); // Empty dependency array

  React.useMemo(async () => {
    setLoad(true);
    try {
      let getChats = await ChatService.getChats(token);
      // console.log(getChats.chats)
      if (getChats.code !== "200") {
        setLoad(false);
      } else {
        setLoad(false);
        // setChatsHistory(getChats.chats);
        selectionChats(getChats.chats);
      }
    } catch (error) {
      console.log(error);
      setLoad(true);
    }
  }, [token]);

  React.useEffect(() => {
    setLoad(true);

    const GetChats = async () => {
      try {
        let getProfile = await ChatService.getChatByProfile(token);
        // console.log(getProfile.profiles)
        if (getProfile.code !== "200") {
          setMatches([]);
        } else {
          if (getProfile.profiles.length > 0) {
            setMatches(getProfile.profiles);
          } else {
            setMatches([]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    GetChats();
  }, [token]);

  const fetchMessages = React.useCallback(async () => {
    setLoad(true);
    if (!selectedChat) return;
    try {
      let getMessages = await ChatService.getMessages(selectedChat._id, token);
      if (getMessages.code !== "success") {
        setLoad(false);
      } else {
        setLoad(false);
        setMessages(getMessages.messages);

        // console.log("setMessages:", getMessages.messages);
        socket.emit("join chat", selectedChat._id);
      }
    } catch (error) {
      console.log(error.message);
    }
  }, [selectedChat, token]);

  React.useEffect(() => {
    fetchMessages();
    selectedChatCompare = selectedChat;
  }, [fetchMessages, selectedChat]);

  const typingHandler = (e) => {
    setCurrentMessage(e.target.value);
    if (!socketConnected) return;
    if (!tyPing) {
      setTyPing(true);
      socket.emit("typing", selectedChat._id);
    }

    let lastTypingTime = new Date().getTime();
    let timerLength = 3000;
    setTimeout(() => {
      let timeNow = new Date().getTime();
      let timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && tyPing) {
        socket.emit("stop typing", selectedChat._id);
        setTyPing(false);
      }
    }, timerLength);
  };

  const SendMesagge = async (e) => {
    e.preventDefault();
    let formData = {
      content: currentMessage,
      chatId: selectedChat._id,
    };
    if (currentMessage !== "") {
      socket.emit("stop typing", selectedChat._id);
      try {
        setCurrentMessage("");
        const sendMessage = await ChatService.sendMessage(formData, token);
        // console.log(sendMessage)
        if (sendMessage.code !== "success") {
          // console.log(sendMessage)
        } else {
          console.log("Sending message:", sendMessage.message);
          socket.emit("new message", sendMessage.message);
          setMessages([...messages, sendMessage.message]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const unMatchHandler = async (accountId, token) => {
    setLoad(true);
    try {
      let unMatch = await ChatService.unMatchUser(accountId, token);
      console.log(unMatch);
      if (unMatch.code !== "200") {
        setLoad(false);
        toast.error("Hubo un error al intentar deshacer match");
      } else {
        toast.success("Se eliminó el match correctamente");
        setLoad(false);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  React.useEffect(() => {
    const messageReceivedHandler = (newMessageRecieved) => {
      console.log("Received 'message recieved' event", newMessageRecieved);
      //   console.log("Selected chat compare:", selectedChatCompare);
      if (
        !selectedChatCompare ||
        selectedChatCompare._id !== newMessageRecieved.chat._id
      ) {
        //give notification
      } else {
        setMessages((prevMessages) => [...prevMessages, newMessageRecieved]);
      }
    };

    socket.on("message recieved", messageReceivedHandler);

    // Clean up the event listener when the component unmounts or before setting up a new one
    return () => {
      socket.off("message recieved", messageReceivedHandler);
    };
  }, [messages]); // Removed 'messages' from the dependency array

  return (
    <>
      <Header />
      <Box
        sx={{
          marginTop: { xs: "5vh", sm: "5vh", md: "8vh" },
          display: "flex",
          width: "100%",
          height: { xs: "90vh" },
          flexDirection: { xs: "column", md: "row" },
          position: "relative",
          zIndex: 111,
        }}
      >
        <Box
          display={{ xs: selectedChat ? "none" : "block", md: "block" }}
          sx={{
            width: { xs: "95%", md: "25%" },
            m: 2,
          }}
        >
          <Typography sx={{ ml: 2, fontSize: 18, mt: 2 }}>
            Mis conexiones
          </Typography>
          <Box
            sx={{
              borderRight: "solid 0.1vw #ADADAD",
            }}
          >
            {matches.length > 0 ? (
              <Swiper
                watchSlidesProgress={true}
                slidesPerView={5}
                className="tags-swiper"
              >
                {matches.length > 0 &&
                  matches.map((match, index) => (
                    <SwiperSlide key={index}>
                      <Box
                        key={index}
                        sx={{
                          borderRadius: "50%",
                          width: 55,
                          height: 55,
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transition: "ease-in-out 200ms",
                          "&:hover": {
                            backgroundColor: "#DEDEDE",
                          },
                        }}
                        onClick={() => openHistoryAccount(match)}
                      >
                        <Avatar
                          alt={match.fullname}
                          src={match.pic_profile}
                          sx={{
                            width: 45,
                            height: 45,
                          }}
                        />
                      </Box>
                    </SwiperSlide>
                  ))}
              </Swiper>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  my: 2,
                }}
              >
                <Typography sx={{ width: "95%", textAlign: "center" }}>
                  En el momento no tienes ningun match
                </Typography>
              </Box>
            )}
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box
            sx={{
              py: 1,
              px: 1,
              borderRight: "solid 0.1vw #ADADAD",
            }}
          >
            <Stack
              sx={{
                overflowY: "scroll",
                height: 380,
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              {chats.length > 0 &&
                chats.map((item, index) => (
                  <ChatContent
                    key={index}
                    data={item}
                    account={account}
                    selectionSelectedChat={selectionSelectedChat}
                  />
                ))}
            </Stack>
          </Box>
        </Box>

        <ChatMessages
          account={account}
          selectedChat={selectedChat}
          chats={chats}
          load={load}
          messages={messages}
          currentMessage={currentMessage}
          sendMesage={SendMesagge}
          typingHandler={typingHandler}
          removeSelectedChat={removeSelectedChat}
          isTyPing={isTyPing}
          unMatchHandler={unMatchHandler}
          token={token}
        />

        {selectedAccount && (
          <AccountHistories
            openHistoryCardsModal={openHistoryCardsModal}
            setOpenHistoryCardsModal={setOpenHistoryCardsModal}
            selectedAccount={selectedAccount}
          />
        )}
      </Box>
      <Box
        component={"img"}
        src={Wave1}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 110,
        }}
      />
      <Box
        component={"img"}
        src={Wave2}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 80,
        }}
      />
    </>
  );
};

export default Chats;
