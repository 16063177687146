import * as React from "react";
import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LogoColor from "../assets/images/LogoColor.svg";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Waves from "../assets/images/waves.svg";
import LoadingButton from "@mui/lab/LoadingButton";

import useAuth from "../context/auth/useAuth";
import useSelectLang from "../context/lang/useSelectLang"

//service
import AuthService from "../services/AuthService";
import { Alert, Modal } from "@mui/material";
// import { toast } from "react-toastify";

import { RemoveTilde } from '../utils/Util'

const initialData = {
    username: "",
    password: ""
}

export default function SignIn() {
    const { lang, Lang } = useSelectLang()
    const selectedLang = Lang(lang)
    const { loggin } = useAuth();
    // const userData = user;
    // const token = userData?.accessToken ? userData.accessToken : null;
    const [formData, setFormData] = React.useState(initialData)
    const [errors, setErrors] = React.useState({})
    const [noty, setNoty] = React.useState({})
    const [load, setLoad] = React.useState(false)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [modalEmail, setModalEmail] = React.useState("")

    const RecoverPassword = async (e) => {

        e.preventDefault()
        const info = { email: modalEmail }

        try {
            const data = await AuthService.checkEmail(info);
            if (data.code === "403") return setNoty({ notyErrorRecover: "Oops parece que el correo que ingresaste no se encuentra registrado" })
            await AuthService.recoverPassword(info)
            setNoty({ notyRecover: "Se ha enviado un correo electronico a tu email con los pasos a seguir" })
        } catch (error) {
            console.log(error)
            setNoty({ notyErrorRecover: error.message })
        }

    }

    //handler iunputs to all TextFields
    const handlerInputChange = (e) => {
        setErrors({});
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData(formInfo => {
            return {
                ...formInfo,
                [name]: value
            }
        })
    }

    const handleSubmit = async(e) => {

        e.preventDefault();

        let newFormData = {
            ...formData,
            username: RemoveTilde(formData.username).toLowerCase(),
            password: formData.password,
        }

        setLoad(true);
        let newErrors = {};

        if (formData.username === "") newErrors.username = "El usuario es requerido";
        if (formData.password === "") newErrors.password = "La contraseña es requerida";

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await AuthService.signIn(newFormData);
                // console.log(response)
                loggin(response);
                setLoad(false);
                // navigate("/home")
            } catch (error) {
                console.log(error)
                setErrors({ formError: error.message })
                setLoad(false);
            }
        } else {
            setErrors(newErrors);
            setLoad(false)
        }

    };

    return (
        <>
            <Container component="main" maxWidth="xs" sx={{
                position: "relative",
                zIndex: 100
            }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar src={LogoColor} sx={{ width: 100, height: 100, m: 4 }} />
                    <Typography component="h1" variant="h5" textAlign={"center"}>
                        {selectedLang.login.welcomeText}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={(e) => handleSubmit(e)}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label={selectedLang.login.labelUsername}
                            name="username"
                            autoComplete="username"
                            autoFocus
                            error={!!errors.username}
                            onChange={(e) => { handlerInputChange(e) }}
                            helperText={errors.username ? errors.username : ""}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={selectedLang.login.labelPassword}
                            onChange={(e) => { handlerInputChange(e) }}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            error={!!errors.password}
                            helperText={errors.password ? errors.password : ""}
                        />
                        <Grid container>
                            <Grid item xs={12}>
                                {
                                    errors.formError && <Alert severity="error">{errors.formError}</Alert>
                                }
                            </Grid>
                        </Grid>
                        <LoadingButton
                            loading={load}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {selectedLang.login.btnLogin}
                        </LoadingButton>
                    </Box>

                    <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2" onClick={handleOpen}>
                                    {selectedLang.login.forgotPassword}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {selectedLang.login.linkRegister}
                                </Link>
                            </Grid>
                        </Grid>
                </Box>
                {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
            </Container>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "95%", md: 600 },
                        bgcolor: "background.paper",
                        borderRadius: 2.5,
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Ingresa tu correo electronico para recuperar tu contraseña
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={(e) => { RecoverPassword(e) }}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            value={modalEmail}
                            type="email"
                            onChange={(e) => { setNoty({}); setModalEmail(e.target.value) }}
                            label="Correo electronico"
                            autoFocus
                        />

                        {
                            noty.notyErrorRecover && <Alert severity="error">{noty.notyErrorRecover}</Alert>
                        }


                        {
                            noty.notyRecover ?
                                (<Alert severity="info">{noty.notyRecover}</Alert>)
                                :
                                (<LoadingButton
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Recuperar contraseña
                                </LoadingButton>)
                        }

                    </Box>
                </Box>
            </Modal>
            <Box
                component={"img"}
                src={Waves}
                sx={{ width: "100%", bottom: 0, position: "absolute" }}
            />
        </>
    );
}
