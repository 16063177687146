import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import "swiper/css";

//Icons
import ClearIcon from '@mui/icons-material/Clear';

const TagSlider = ({ tags, slides = 3, deleteTag }) => {

  // console.log(window.innerWidth)

  return (
    <Swiper
      watchSlidesProgress={true}
      slidesPerView={slides}
      className="tags-swiper"
    >
      {tags.length > 5 ? (
        <>
          <SwiperSlide key={tags[0]}>
            <Typography
              variant="contained"
              color="white"
              sx={{
                borderRadius: 5,
                backgroundColor: (theme) => theme.palette.primary.main,
                paddingX: 3,
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: 30,
                textAlign: "center",
                margin: 1,
                justifyContent: "center",
                fontSize: 16
              }}
            >
              {tags[0]}
            </Typography>
          </SwiperSlide>
          <SwiperSlide key={tags[1]}>
            <Typography
              variant="contained"
              color="white"
              sx={{
                borderRadius: 5,
                backgroundColor: (theme) => theme.palette.primary.main,
                paddingX: 3,
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: 30,
                textAlign: "center",
                margin: 1,
                justifyContent: "center",
                fontSize: 16
              }}
            >
              {tags[1]}
            </Typography>
          </SwiperSlide>
          <SwiperSlide key={tags[2]}>
            <Typography
              variant="contained"
              color="white"
              sx={{
                borderRadius: 5,
                backgroundColor: (theme) => theme.palette.primary.main,
                paddingX: 3,
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: 30,
                textAlign: "center",
                margin: 1,
                justifyContent: "center",
                fontSize: 16
              }}
            >
              {tags[2]}
            </Typography>
          </SwiperSlide>
          <SwiperSlide key={tags[3]}>
            <Typography
              variant="contained"
              color="white"
              sx={{
                borderRadius: 5,
                backgroundColor: (theme) => theme.palette.primary.main,
                paddingX: 3,
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: 30,
                textAlign: "center",
                margin: 1,
                justifyContent: "center",
                fontSize: 16
              }}
            >
              {tags[3]}
            </Typography>
          </SwiperSlide>
          <SwiperSlide key={tags[4]}>
            <Typography
              variant="contained"
              color="white"
              sx={{
                borderRadius: 5,
                backgroundColor: (theme) => theme.palette.primary.main,
                paddingX: 3,
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: 30,
                textAlign: "center",
                margin: 1,
                justifyContent: "center",
                fontSize: 16
              }}
            >
              {tags[4]}
            </Typography>
          </SwiperSlide>
          <SwiperSlide key={tags.length}>
            <Typography
              variant="contained"
              color="white"
              sx={{
                borderRadius: 5,
                backgroundColor: (theme) => theme.palette.primary.main,
                paddingX: 3,
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: 30,
                textAlign: "center",
                margin: 1,
                justifyContent: "center",
                fontSize: 16
              }}
            >
              {` + ${tags.length - 5}`}
            </Typography>
          </SwiperSlide>
        </>
      ) : (
        tags?.map((tag, index) => (
          <SwiperSlide key={index}>
            <Box
              sx={{
                borderRadius: 5,
                backgroundColor: (theme) => theme.palette.primary.main,
                paddingX: 3,
                display: "flex",
                alignItems: "center",
                width: "auto",
                height: 30,
                textAlign: "center",
                margin: 1,
                justifyContent: "space-between",
                fontSize: 16
              }}
            >
              <Typography
                variant="contained"
                color="white"
              >
                {tag}
              </Typography>
              <IconButton onClick={() => deleteTag(tag)} sx={{color:'white'}}>
                <ClearIcon />
              </IconButton>
            </Box>
          </SwiperSlide>
        ))
      )}
    </Swiper>
  );
};

export default TagSlider;
