import { createContext, useState } from 'react'

const selectedChatStorage = JSON.parse(sessionStorage.getItem("hoshii_selectedChat"))
const selectionAllChatStorage = JSON.parse(sessionStorage.getItem("hoshii_selectionChats"))

const ChatProvider = ({ children }) => {
    const [selectedChat, setSelectedChat] = useState(selectedChatStorage || null)
    const [chats, setChats] = useState(selectionAllChatStorage || [])
    const [notifications, setNotifications] = useState([])
    // console.log(user)

    // const updateAccountInfo = (accountInfo) => {
    //     // setAccount(accountInfo)

    //     sessionStorage.setItem("accountInfo", JSON.stringify(accountInfo))
    // }

    const selectionSelectedChat = (selectedChat) => {
        sessionStorage.setItem("hoshii_selectedChat", JSON.stringify(selectedChat))
        setSelectedChat(selectedChat)
    }

    const removeSelectedChat = () => {
        sessionStorage.removeItem("hoshii_selectedChat")
        setSelectedChat(null)
    }

    const selectionChats = (chats) => {
        sessionStorage.setItem("hoshii_selectionChats", JSON.stringify(chats))
        setChats(chats)
    }
    // console.log(account)

    const contextValue = {
        // updateAccountInfo,
        selectedChat,
        setSelectedChat,
        selectionSelectedChat,
        setChats,
        chats,
        selectionChats,
        notifications,
        setNotifications,
        removeSelectedChat
    }

    return (
        <ChatContext.Provider value={contextValue}>
            {children}
        </ChatContext.Provider>
    )
}

export const ChatContext = createContext()
export default ChatProvider