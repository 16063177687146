import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';

//Utils


// import AdminService from "../../services/AdminService";
import AccountService from "../../services/AccountService";
import useAuth from "../../context/auth/useAuth";

//icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

//Toast
import { toast } from 'react-toastify';
import { RemoveTilde } from '../../utils/Util';
import TagSlider from '../TagSlider';

const ModalCreateNewMaster = ({open, setOpen, fetchMasterCards}) => {
    const { user } = useAuth();
    const token = user ? user.accessToken : null;

    // const [contentType, setContentType] = React.useState(false)
    const [errors, setErrors] = React.useState({})
    const [selectedImage, setSelectedImage] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const uploadForm = React.useRef(null);

    const [currentInputTagText, setCurrentInputTagText] = React.useState("");

    // const [tagList, setTagList] = React.useState([]);

    const [contentData, setContentData] = React.useState({
        title: "",
        cardtags: ["master"],
        type: "Master",
        description: "",
        file: {},
    });

    //Functions
    const handleClose = () => {
        setOpen(false);
        setContentData({
            title: "",
            cardtags: [],
            type: "Profile",
            description: "",
            file: {},
        })
        setSelectedImage("")
    };

    const addToImage = () => {
        const inputChangeImage = document.getElementById("addImageToCard")
        inputChangeImage.click()
    }

    const addTagList = (tag) => {
        let oldTagList = contentData.cardtags
        let findTag = oldTagList.find((t) => t === tag)
        setCurrentInputTagText("")
        if (findTag) return
        if (tag === "ad" || tag === "master" || tag === "maestro" || tag === "anuncio") return
        if(tag.length > 30) return toast.error("La tag solo puede contener 30 caracteres", {autoClose:2000})
        let ValidationTag = RemoveTilde(tag)
        let newTagList = [...oldTagList, ValidationTag.toLowerCase()]
        setContentData({ ...contentData, cardtags: newTagList })
    }

    const deleteTag = (tag) => {
        let oldTagList = contentData.cardtags
        const tagListNoNTag = oldTagList.filter((t) => t !== tag)
        setContentData({ ...contentData, cardtags: tagListNoNTag })
    }

    const handleInputImagePic = (e) => {
        e.preventDefault();
        const [file] = e.target.files;
        const imgSize7Mb = 7 * 1024 * 1024;
        const isValidSize = file.size < imgSize7Mb;
        const isNameOfOneImageRegEx = /.(jpe?g|gif|png|webp)$/i;
        const isValidType = isNameOfOneImageRegEx.test(file.name);

        if (!isValidSize) {
            e.target.value = "";
            return (
                toast.error("El tamaño de la imagen es demasiado grande, máx. 8mb")
                // console.log('Hubo un error al cargar la imagen')
            );
        }
        if (!isValidType) {
            e.target.value = "";
            return (
                toast.error("Sólo se admiten imágenes")
            )
        }

        const reader = new FileReader();
        reader.onloadstart = () => {
            // setLoad(true);
        };
        reader.onloadend = () => {
            // console.log(reader.result)
            // setLoad(false);
            setSelectedImage(reader.result);
            setContentData({ ...contentData, file: reader.result })
            // let btnSubmitUpdate = document.getElementById("btnSubmitUpdateProfile");
            // btnSubmitUpdate.click();
        };
        reader.readAsDataURL(file);

        // handleFormChange()
    };

    const createCardAd = async () => {

        setLoading(true)
        const newErrors = {}

        if (selectedImage === "") newErrors.image = "La imagen de la card es requerida"
        if (contentData.title === "") newErrors.title = "El título es requerido"
        if (contentData.title.length < 5) newErrors.title = "El título es demasiado corto"
        // if (contentData.title.length > 80) newErrors.title = "El título es demasiado largo máx. 80 caracteres"
        if (contentData.description === "") newErrors.description = "La descripción es requerida"
        if (contentData.description.length < 10) newErrors.description = "La descripción debe contener al menos 30 caracteres"
        if (contentData.cardtags.length < 1) newErrors.cardtags = "Debes indicar al menos 1 tags"

        // console.log(contentData.cardtags.length)

        const form = uploadForm.current;
        const fileInput = Array.from(form.elements).find(
            ({ name }) => name === "file"
        );

        const formData = new FormData();

        formData.append("title", contentData.title)
        formData.append("description", contentData.description)
        formData.append("type", contentData.type)
        formData.append("cardtags", JSON.stringify(contentData.cardtags))
        for (const file of fileInput.files) {
            formData.append("file", file);
        }

        if (Object.keys(newErrors).length === 0) {
            //submitting
            try {
                // await AdminService.createAd(formData, token)
                const createCard = await AccountService.createCard(formData, token)
                // console.log(createCard)
                if (createCard.code === '500') {
                    setLoading(false)
                    newErrors.formError = "Hubo un error al crear la publicación"
                    setErrors(newErrors);
                    setTimeout(() => {
                        setErrors({});
                    }, 7000);
                } else {
                    setLoading(false)
                    handleClose()
                    fetchMasterCards()
                    toast.success("Maestro creado exitosamente", {autoClose:3000})
                }

            } catch (error) {
                console.log(error)
                setLoading(false)
                newErrors.formError = "Hubo un error al crear la publicación"
                setErrors(newErrors);
                setTimeout(() => {
                    setErrors({});
                }, 7000);
            }
        } else {
            setLoading(false)
            setErrors(newErrors);
            setTimeout(() => {
                setErrors({});
            }, 7000);
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
            scroll={'body'}
        >
            <DialogTitle id="alert-dialog-title">
                {"Crear Card"}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%' } }}>
                    {
                        <Typography
                            sx={{
                                fontSize: 16,
                                color: 'red',
                                mb: 3
                            }}
                        >{errors.formError}</Typography>
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'column', md: 'row' }
                    }}
                >
                    <Box
                        sx={{ width: { xs: '100%', sm: '100%', md: '40%' } }}
                    >
                        <Box component={'form'} ref={uploadForm}>
                            <Box hidden component={'input'} type='file' id="addImageToCard" accept='image/*' name='file' onChange={(e) => handleInputImagePic(e)} />
                        </Box>
                        <Box sx={{display:'flex', justifyContent:'center', mb:2}}>
                            {
                                selectedImage !== "" ? (
                                    <>
                                        <Box
                                            sx={{
                                                width: { xs: 270, sm: 270, md: 320 },
                                                height: { xs: 300, sm: 300, md: 350 },
                                                backgroundColor: '#dedede',
                                                border: "1px dashed #000",
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                overflow: 'hidden',
                                                borderRadius: 3,
                                            }}
                                        >
                                            <Box
                                                component={'img'}
                                                alt='Selected image card'
                                                src={selectedImage}
                                                sx={{
                                                    height: '100%'
                                                }}
                                            />
                                        </Box>
                                    </>
                                )
                                    :
                                    (
                                        <>
                                            <Box
                                                sx={{
                                                    width: { xs: 270, sm: 270, md: 320 },
                                                    height: { xs: 300, sm: 300, md: 350 },
                                                    backgroundColor: '#dedede',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    transition: "0.3s ease",
                                                    borderRadius: 3,
                                                    '&:hover': {
                                                        cursor: 'pointer',
                                                        backgroundColor: '#AAAAAA'
                                                    }
                                                }}
                                                onClick={() => addToImage()}
                                            >
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                }}>
                                                    <Typography sx={{ fontSize: 32 }} color={'primary'}>Cargar imagen</Typography>
                                                    <AddCircleOutlineIcon sx={{ width: 110, height: 110 }} color={'primary'} />
                                                </Box>
                                            </Box>
                                            <Typography color={'red'}>{!!errors.image && errors.image}</Typography>
                                        </>
                                    )
                            }
                        </Box>

                    </Box>
                    <Box
                        sx={{ width: { xs: '100%', sm: '100%', md: '60%' }, px: 2 }}
                    >
                        <Box>
                            <Box>
                                {
                                    contentData.cardtags && contentData.cardtags.length > 0 && (
                                        <TagSlider tags={contentData.cardtags} slides={window.innerWidth < 570 ? 1 : 2} deleteTag={deleteTag}/>
                                    )
                                }
                                {/* <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{
                                        display: 'flex',
                                        overflowX: 'scroll',
                                        paddingLeft: 1,
                                        paddingRight: 1,
                                        paddingY: 2
                                    }}
                                >
                                    {
                                        contentData.cardtags && contentData.cardtags.length > 0 && contentData.cardtags.map((tag, index) => (
                                            <Typography
                                                variant="contained"
                                                component={'p'}
                                                color="white"
                                                sx={{
                                                    borderRadius: 5,
                                                    marginRight: 2,
                                                    backgroundColor: (theme) => theme.palette.primary.main,
                                                    paddingX: 3,
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                                key={index}
                                            >{tag}
                                                <IconButton onClick={() => deleteTag(tag)} sx={{ width: 18, height: 18 }} aria-label="remove">
                                                    <ClearIcon sx={{ width: 18, height: 18, color: 'white', ml: 2 }} />
                                                </IconButton>
                                            </Typography>
                                        ))
                                    }
                                </Stack> */}
                            </Box>
                            <Box
                                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <TextField
                                    fullWidth
                                    label='Añade tu tag'
                                    name='tag'
                                    id='selecterTags'
                                    type='text'
                                    value={currentInputTagText}
                                    onChange={(e) => setCurrentInputTagText(e.target.value)}
                                    autoComplete='off'
                                    error={!!errors.cardtags}
                                    helperText={!!errors.cardtags && errors.cardtags}
                                />
                                <IconButton onClick={() => addTagList(currentInputTagText)} sx={{ width: 50, height: 50 }} aria-label="add">
                                    <AddCircleOutlineIcon sx={{ width: 50, height: 50 }} />
                                </IconButton>
                            </Box>
                            <Box
                                component={'form'}
                                sx={{ mt: 2 }}
                                autoComplete="off"
                                noValidate
                            >
                                <FormControl variant="outlined" required={true} sx={{ width: '100%', my: 2 }}>
                                    <OutlinedInput
                                        id="title"
                                        type="text"
                                        name="title"
                                        size='medium'
                                        error={!!errors.title}
                                        value={contentData.title}
                                        label={"Nombre de la publicación"}
                                        onChange={(e) => setContentData({ ...contentData, title: e.target.value })}
                                    />
                                    <FormHelperText sx={{ color: 'red' }} id="my-helper-text">{!!errors.title && errors.title}</FormHelperText>
                                    <InputLabel htmlFor="fistName">Nombre de la publicación</InputLabel>
                                </FormControl>

                                <FormControl variant="outlined" required={true} sx={{ width: '100%', mb: 2 }}>
                                    <InputLabel htmlFor="fistName">Descripción</InputLabel>
                                    <OutlinedInput
                                        id="description"
                                        type="text"
                                        name="description"
                                        label="Descripcion"
                                        value={contentData.description}
                                        size='medium'
                                        error={!!errors.description}
                                        onChange={(e) => setContentData({ ...contentData, description: e.target.value })}
                                    />
                                    <FormHelperText sx={{ color: 'red' }} id="my-helper-text">{!!errors.description && errors.description}</FormHelperText>
                                </FormControl>

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                {
                    !loading && (
                        <>
                            <Button onClick={handleClose}>Cancelar</Button>
                            <Button onClick={() => createCardAd()} autoFocus>
                                Crear Card
                            </Button>
                        </>
                    )
                }
                {
                    loading && (
                        <Typography>Cargando...</Typography>
                    )
                }
            </DialogActions>
        </Dialog>
    )
}

export default ModalCreateNewMaster