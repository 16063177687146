import ConfigServer from "../settings/config"

const AdminService = {}

const CREATE_AD_URL_API =  `${ConfigServer.urlServer}/api/masters/ad/create`
const CREATED_MASTER_URL_API = `${ConfigServer.urlServer}/api/masters/master/create`
const GET_MASTER_CARDS_URL_API = `${ConfigServer.urlServer}/api/masters/get-master/admin`

AdminService.createAd = async (formdata, token) => {
    let createdAd = await fetch(CREATE_AD_URL_API,{
        method:'POST',
        headers:{
            "Authorization": `Bearer ${token}`
        },
        body: formdata
    })
    if(!createdAd.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }

    let data = await createdAd.json()
    let response = {
        code:data.code,
        cardAd: data.cardAd
    }
    return response
}

AdminService.createMaster = async (formdata, token) => {
    let createdAd = await fetch(CREATED_MASTER_URL_API,{
        method:'POST',
        headers:{
            "Authorization": `Bearer ${token}`
        },
        body: formdata
    })
    if(!createdAd.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }

    let data = await createdAd.json()
    let response = {
        code:data.code,
        cardMaster: data.cardMaster
    }
    return response
}

AdminService.getMastercards = async (token, skip, limit) => {
    let getMasterCards = await fetch(`${GET_MASTER_CARDS_URL_API}/${skip}/${limit}`,{
        method:'GET',
        headers:{
            "Authorization": `Bearer ${token}`
        }
    })
    if(!getMasterCards.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }

    let data = await getMasterCards.json()
    let response = {
        code:data.code,
        masterCards: data.fetchcards,
        count: data.counts
    }
    return response
}

export default AdminService