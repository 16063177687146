import config from '../settings/config'
const FIND_ALL_TAGS_API_URL = `${config.urlServer}/api/tags/get-top10`
const FIND_TAGS_BY_TAG_API_URL = `${config.urlServer}/api/tags/get-tags`

const TagService = {}

TagService.findAllTags = async (token) => {
    if(token){
        const response = await fetch(FIND_ALL_TAGS_API_URL, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if(!response.ok) return new Error('Something went wrong!, please try again');
        const data = await response.json()
        return data
    }else{
        throw new Error("No Authorize")
    }
}

TagService.findTagsByTag = async (token, tag) => {
    if(token){
        const response = await fetch(`${FIND_TAGS_BY_TAG_API_URL}/${tag}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if(!response.ok) return new Error('Something went wrong!, please try again');
        const data = await response.json()
        return data
    }else{
        throw new Error("No Authorize")
    }
}

export default TagService