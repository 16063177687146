import config from '../settings/config'

const GET_CHATS_URL_API = `${config.urlServer}/api/chat/get-chats`
const GET_CHATS_BY_PROFILES = `${config.urlServer}/api/home/profiles`
const GET_CHAT_MESSAGES_URL_API = `${config.urlServer}/api/chat/get-all-messages`
const SEND_MESSAGE_URL_API = `${config.urlServer}/api/chat/send-message`
const UNMATCH_API_URL = `${config.urlServer}/api/chat/dismatch`

const ChatService = {}

ChatService.getChats = async (token) => {
    let getChats = await fetch(GET_CHATS_URL_API,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    if(!getChats.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await getChats.json()

    let response = {
        code: data.code,
        chats: data.chats
    }
    return response
}

ChatService.getChatByProfile = async (token) => {
    let getChatByProfile = await fetch(GET_CHATS_BY_PROFILES,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    if(!getChatByProfile.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await getChatByProfile.json()

    let response = {
        code: data.code,
        profiles: data.profiles
    }
    return response
}

ChatService.getMessages = async (selectedChat, token) => {
    let getMessages = await fetch(`${GET_CHAT_MESSAGES_URL_API}/${selectedChat}`,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    if(!getMessages.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await getMessages.json()

    let response = {
        code: data.code,
        messages: data.messages
    }
    return response
}

ChatService.sendMessage = async(formData, token) => {
    let sendMessage = await fetch(SEND_MESSAGE_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!sendMessage.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await sendMessage.json()

    let response = {
        code: data.code,
        message: data.message
    }
    return response
}

ChatService.unMatchUser = async (accountId, token) => {
    let unMatch = await fetch(UNMATCH_API_URL,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify({accountId: accountId})
    })
    if(!unMatch.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await unMatch.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

export default ChatService