import { CssBaseline } from "@mui/material";
import RootRouter from "./routes/index";
import ThemeProvider from "./context/theme/ThemeProvider";
import ChatContext from "./context/chat/ChatContext";
import NotyProvider from "./context/notification/NotyProvider";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LangProvider from "./context/lang/LangProvider";

function App() {
    return (
        <>
            <LangProvider>
                <ThemeProvider>
                    <ChatContext>
                        <NotyProvider>
                            <CssBaseline />
                            <RootRouter />
                        </NotyProvider>
                    </ChatContext>
                    <ToastContainer />
                </ThemeProvider>
            </LangProvider>
        </>
    );
}

export default App;
