import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Typography } from '@mui/material';

const ErrorNotification = ({openErrorNotificationModal, setOpenErrorNotificationModal}) => {

    const handleClose = () => {
        setOpenErrorNotificationModal(false);
    };

    return (
        <Dialog
            open={openErrorNotificationModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Ha ocurrido un error con nuestro servicio"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Si puedes ver este mensaje es porque ocurrio un error al intentar acceder a la información en nuestro sistema.
                    Por favor contactese con nuestro equipo de soporte para solucionar este inconveniente lo antes posible.
                </DialogContentText>
                <Typography sx={{mt:3}}>Nuestro número de contacto es: 301 756-4383</Typography>
                <Typography>Nuestro email para contactarnos es: soporte@hoshii.co</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Aceptar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ErrorNotification