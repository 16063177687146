import React, { useState } from "react";
import Header from "../components/Header";
import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";

import useAuth from "../context/auth/useAuth";
//icons
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardProfile from "../components/profile/CardProfile";
import DetailCard from "../components/profile/DetailCard";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

//Services
import AccountService from "../services/AccountService";
// import CreateCard from "../components/profile/CreateCard";

//Waves
import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";

//navigation
import { useNavigate } from "react-router-dom";

// import SliderComponent from "../components/SliderComponent";
import ErrorNotification from "../components/notifications/ErrorNotification";
import { toast } from "react-toastify";

//social media icons
import facebook from "../assets/socialMedia/facebook.png";
import instagram from "../assets/socialMedia/instagram.png";
import snapchat from "../assets/socialMedia/snapchat.png";
import linkedin from "../assets/socialMedia/linkedin.png";
import tiktok from "../assets/socialMedia/tiktok.png";
import twitter from "../assets/socialMedia/twitter.png";
import onlyfans from "../assets/socialMedia/onlyfans.png";

const socialMediaArrayImages = [
  {
    name: "facebook",
    image: facebook,
  },
  {
    name: "instagram",
    image: instagram,
  },
  {
    name: "twitter",
    image: twitter,
  },
  {
    name: "tiktok",
    image: tiktok,
  },
  {
    name: "linkedin",
    image: linkedin,
  },
  {
    name: "snapchat",
    image: snapchat,
  },
  {
    name: "onlyfans",
    image: onlyfans,
  },
];

const Profile = () => {
  const { user } = useAuth();
  const [openDetailCard, setOpenDetailCard] = React.useState(false);
  // const [openCreateCard, setOpenCreateCard] = React.useState(false);
  const [openErrorNotificationModal, setOpenErrorNotificationModal] =
    React.useState(false);

  const userData = user;
  const token = userData?.accessToken ? userData.accessToken : null;

  const [error, setError] = React.useState("");
  const [load, setLoad] = React.useState(false);

  const [newAccount, setNewAccount] = React.useState("");
  const [usertags, setUserTags] = React.useState([]);

  const [profileCards, setProfileCards] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);

  const [currentCardModal, setCurrentCardModal] = React.useState(null);

  const [fullTags, setFullTags] = useState(false);

  const navigate = useNavigate();

  const [socialMediaArray, setSocialMediaArray] = useState([])

  // console.log(newAccount.active)

  //Functios
  const DeleteCard = async (id) => {
    try {
      await AccountService.deleteCard(token, id);

      const newCardsArray = profileCards.filter((item) => item._id !== id);
      setProfileCards(newCardsArray);
      setFilteredList(newCardsArray);

      setOpenDetailCard(false);
      toast.success("La card se ha eliminado correctamente", {
        autoClose: 2000,
      });
    } catch (error) {
      console.log(error);
      toast.error("Hubo un error al eliminar la card", { autoClose: 2000 });
    }
  };

  const getAccountData = React.useCallback(async () => {
    setLoad(true);
    try {
      const accountData = await AccountService.getAccount(token);
      if (accountData.code === "EXISTACCOUNT") {

        const availableSocialMedia = accountData.account.socialMedia.filter((item) => item.url !== "")

        const baseArray = [];

        availableSocialMedia.forEach(item => {

          socialMediaArrayImages.forEach(socialImage => {
            console.log(socialImage)
            if(socialImage.name === item.name){
              baseArray.push({
                name: item.name,
                url: item.url,
                image: socialImage.image
              })
            }
          })
        })

        console.log(baseArray)

        setSocialMediaArray(baseArray);
        
        setNewAccount(accountData.account);
        setUserTags(accountData.account.usertags);
        setLoad(false);
      } else {
        setError(accountData.msg);
        setLoad(false);
        setOpenErrorNotificationModal(true);
      }
    } catch (error) {
      console.log(error);
      setLoad(false);
      setError("Failed to load server!, please try later");
    }
  }, [token]);

  const OpenCardModal = (data) => {
    setCurrentCardModal(data);
    setOpenDetailCard(true);
  };

  React.useEffect(() => {
    getAccountData();
    // console.log(newAccount)
  }, [getAccountData]);

  React.useEffect(() => {
    const GetUserCardsData = async () => {
      try {
        const data = await AccountService.getUserCardsData(token);
        // console.log(data)
        if (data.code !== "GETCARDS") {
          // console.log(data);
          setProfileCards([]);
          setFilteredList([]);
        } else {
          // console.log(data);
          setProfileCards(data.cards);
          setFilteredList(data.cards);
        }
      } catch (error) {
        console.log(error);
        setOpenErrorNotificationModal(true);
      }
    };

    GetUserCardsData();
  }, [token]);

  return (
    <>
      <Header />
      <Box
        sx={{
          width: { xs: "70%", md: "100%" },
          position: "absolute",
          display: newAccount && newAccount.active ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          top: 30,
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <WarningAmberIcon
          sx={{ color: "#8B4DBA", width: 30, height: "auto", mr: 1 }}
        />
        <Typography
          sx={{
            color: "gray",
            fontSize: { xs: 12, md: 18 },
            textAlign: "center",
          }}
        >
          Completa tu perfil para ser visible
        </Typography>
      </Box>
      <Box
        sx={{
          marginTop: { xs: "1vh", sm: "1vh", md: "10vh" },
          height: "90vh",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          marginBottom: { xs: 0, md: 0 },
          position: "relative",
          zIndex: { xs: 99, md: 112 },
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "28vw",
              lg: "28vw",
              xl: "28vw",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "90%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mt: { xs: 10, md: 0 },
              }}
            >
              <Avatar
                alt="Imagen perfil Hoshii"
                src={
                  newAccount && newAccount.pic_profile
                    ? newAccount.pic_profile
                    : "https://res.cloudinary.com/dujeh3ms8/image/upload/v1681996179/chat-app-socket-test/vqueqktx6hcplgdmqcnz.png"
                }
                sx={{
                  width: 130,
                  height: 130,
                  marginBottom: 2,
                }}
              />
              <Typography
                sx={{
                  fontSize: 20,
                  color: "#290C27",
                }}
              >
                {newAccount && newAccount.fullname
                  ? newAccount.fullname
                  : "Nombre"}
              </Typography>
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 2,
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FavoriteBorderIcon
                    sx={{ width: 34, height: 34, mb: 0.2 }}
                    color="primary"
                  />
                  <Typography sx={{ fontSize: 20, color: "#290C27" }}>
                    {newAccount && newAccount.likes ? newAccount.likes : "0"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Diversity1Icon
                    sx={{ width: 34, height: 34, mb: 0.2 }}
                    color="primary"
                  />
                  <Typography sx={{ fontSize: 20, color: "#290C27" }}>
                    {newAccount && newAccount.communities
                      ? newAccount.communities
                      : "0"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ConnectWithoutContactIcon
                    sx={{ width: 34, height: 34, mb: 0.2 }}
                    color="primary"
                  />
                  <Typography sx={{ fontSize: 20, color: "#290C27" }}>
                    {newAccount && newAccount.connections
                      ? newAccount.connections
                      : "0"}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Divider />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <SliderComponent tags={usertags} /> */}
              {usertags.length > 5 && !fullTags ? (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + usertags[0]);
                    }}
                  >
                    {usertags[0]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + usertags[1]);
                    }}
                  >
                    {usertags[1]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + usertags[2]);
                    }}
                  >
                    {usertags[2]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + usertags[3]);
                    }}
                  >
                    {usertags[3]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + usertags[4]);
                    }}
                  >
                    {usertags[4]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      setFullTags(true);
                    }}
                  >
                    {` + ${usertags.length - 5}`}
                  </Button>
                </>
              ) : (
                usertags.map((tag, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + tag);
                    }}
                  >
                    {tag}
                  </Button>
                ))
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                padding: 1,
                height: 100,
                overflowY: "scroll",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              <Typography color={"#290C27"}>
                {newAccount && newAccount.description
                  ? newAccount.description
                  : ""}
              </Typography>
            </Box>
            <Divider />
            <Typography
              sx={{
                mb: 1,
                mt: 1,
                width: "100%",
                display: socialMediaArray.length > 0 ? "flex" : "none",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Redes sociales
            </Typography>
            <Box
              sx={{
                display: socialMediaArray.length > 0 ? "flex" : "none",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                borderTop: "0.1vw solid #ADADAD",
                borderBottom: "0.1vw solid #ADADAD",
              }}
            >
              {socialMediaArray.map((socialMedia) => (
               <a href={socialMedia.url.includes("http") ? socialMedia.url : "https://www." + socialMedia.url}>
                 <Box
                  key={socialMedia.name}
                  sx={{
                    width: 37,
                    height: 37,
                    backgroundImage: "url(" + socialMedia.image + ")",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    margin: 1,
                    cursor: "pointer",
                  }}
                />
               </a>
              ))}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                onClick={() => navigate("/create-card")}
                sx={{
                  bgcolor: "white",
                  mt: 2,
                  paddingX: 4,
                  border: "solid 0.1vw #8B4DBA",
                }}
              >
                <AddCircleOutlineIcon
                  sx={{ width: 50, height: 50, mr: 1 }}
                  color="primary"
                />
                <Typography>Crear Publicación</Typography>
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "74vw" },
            height: { xs: "auto", md: 600 },
            overflowY: { xs: "none", sm: "none", md: "scroll" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "&::-webkit-scrollbar": { display: "none" },
            borderLeft: { xs: "none", md: "solid 0.1vw #ADADAD" },
          }}
        >
          <Box
            sx={{
              width: "95%",
            }}
          >
            <Box sx={{ paddingBottom: 6 }}>
              {!load && error === "" && (
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                  sx={{ justifyContent: "center", my: 3 }}
                >
                  {filteredList.length > 0 ? (
                    filteredList.map((card, index) => (
                      <CardProfile
                        key={index}
                        card={card}
                        setOpenDetailCard={setOpenDetailCard}
                        OpenCardModal={OpenCardModal}
                        profilePic={newAccount && newAccount.pic_profile}
                      />
                    ))
                  ) : (
                    <Typography
                      fontSize={20}
                      sx={{ width: "100%", textAlign: "center" }}
                    >
                      Aun no tienes ninguna publicacion creada.
                    </Typography>
                  )}
                </Stack>
              )}
              {load && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <Typography>Cargando...</Typography>
                </Box>
              )}
              {error && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <Typography>{error}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {currentCardModal && (
        <>
          <DetailCard
            openDetailCard={openDetailCard}
            setOpenDetailCard={setOpenDetailCard}
            card={currentCardModal}
            DeleteCard={DeleteCard}
          />
        </>
      )}
      {/* <CreateCard
        setOpenCreateCard={setOpenCreateCard}
        openCreateCard={openCreateCard}
      /> */}

      <ErrorNotification
        openErrorNotificationModal={openErrorNotificationModal}
        setOpenErrorNotificationModal={setOpenErrorNotificationModal}
      />

      <Box
        component={"img"}
        src={Wave1}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 110,
        }}
      />
      <Box
        component={"img"}
        src={Wave2}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 80,
        }}
      />
    </>
  );
};

export default Profile;
