import ConfigServer from "../settings/config"

const SettingsService = {}

const EDIT_ACCOUNT_DATA_URL_API =  `${ConfigServer.urlServer}/api/settings/info/update`
const EDIT_ACCOUNT_PASSWORD_URL_API =  `${ConfigServer.urlServer}/api/settings/info/update-password`
const DELETE_ACCOUNT_URL_API =  `${ConfigServer.urlServer}/api/settings/info/delete-account`

SettingsService.EditAccountData = async(token, item) => {

    if(token){
        const response = await fetch(EDIT_ACCOUNT_DATA_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

SettingsService.EditAccountPassword = async(token, item) => {

    if(token){
        const response = await fetch(EDIT_ACCOUNT_PASSWORD_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

SettingsService.DeleteAccount = async(token, item) => {

    if(token){
        const response = await fetch(DELETE_ACCOUNT_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}


export default SettingsService