import React from "react";
import Header from "../components/Header";
import {
  Avatar,
  Box,
  Button,
  // Button,
  Divider,
  Modal,
  Stack,
  Typography,
  // TextField,
} from "@mui/material";

import useAuth from "../context/auth/useAuth";
//icons
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Diversity1Icon from "@mui/icons-material/Diversity1";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CardProfile from "../components/profile/CardProfile";
import { useParams } from "react-router-dom";

//Services
import AccountService from "../services/AccountService";
import { Error } from "@mui/icons-material";

//navigation
import { useNavigate } from "react-router-dom";

//Waves
import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";
import DetailcardPublicProfile from "../components/profile/DetailcardPublicProfile";
// import SliderComponent from "../components/SliderComponent";

const PublicProfile = () => {
  const { user } = useAuth();
  const [openDetailCard, setOpenDetailCard] = React.useState(false);
  const params = useParams();
  // const [openCreateCard, setOpenCreateCard] = React.useState(false);

  // console.log(params.id)

  const accountId = params.id;

  const userData = user;
  const token = userData?.accessToken ? userData.accessToken : null;

  const [error, setError] = React.useState("");
  const [load, setLoad] = React.useState(false);

  const [newAccount, setNewAccount] = React.useState("");
  const [usertags, setUserTags] = React.useState([]);

  // const [profileCards, setProfileCards] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);

  const [currentCardModal, setCurrentCardModal] = React.useState(null);

  const [profilePictureModal, setProfilePictureModal] = React.useState(false);

  const [fullTags, setFullTags] = React.useState(false)

  const navigate = useNavigate();

  const getAccountData = React.useCallback(async () => {
    setLoad(true);
    try {
      const accountData = await AccountService.getPublicAccountData(token, accountId);
      console.log(accountData)
      setNewAccount(accountData.account);
      setUserTags(accountData.account.usertags);
      setFilteredList(accountData.account.userCards);
      setLoad(false);
    } catch (error) {
      console.log(error)
      setLoad(false);
      switch (error.name) {
        case "TypeError":
          setError("Failed to load server!, please try later");
          throw new Error("Failed to load server!, please try later");
        default:
          setError("Failed to connection server!, please try later");
          throw new Error("Failed to connection server!, please try later");
      }
    }
  }, [token, accountId]);

  const OpenCardModal = (data) => {
    setCurrentCardModal(data);
    setOpenDetailCard(true);
  };

  React.useEffect(() => {
    getAccountData();
    // console.log(newAccount)
  }, [getAccountData]);

  // React.useEffect(() => {
  //   const GetUserCardsData = async () => {
  //     const data = await AccountService.getUserCardsData(token);

  //     // console.log(data.cards);

  //     // setProfileCards(data.cards);
  //     setFilteredList(data.cards);
  //   };

  //     GetUserCardsData();

  // }, [token]);

  return (
    <>
      <Header />
      <Box
        sx={{
          marginTop: { xs: "1vh", sm: "1vh", md: "10vh" },
          height: "75vh",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          marginBottom: { xs: 0, md: 10 },
          zIndex: 99,
          position: "relative"
        }}
      >
        <Box
          sx={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "28vw",
              lg: "28vw",
              xl: "28vw",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "90%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mt: { xs: 10, md: 0 },
              }}
            >
              <Avatar
                alt="Imagen perfil Hoshii"
                onClick={() => {setProfilePictureModal(true)}}
                src={
                  newAccount.pic_profile
                    ? newAccount.pic_profile
                    : "https://res.cloudinary.com/dujeh3ms8/image/upload/v1681996179/chat-app-socket-test/vqueqktx6hcplgdmqcnz.png"
                }
                sx={{
                  width: 130,
                  height: 130,
                  marginBottom: 2,
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  fontSize: 20,
                  color: "#290C27",
                }}
              >
                {newAccount.fullname ? newAccount.fullname : "Nombre"}
              </Typography>
            </Box>
            <Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: 5,
                  paddingRight: 5,
                  marginTop: 2,
                  mb: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FavoriteBorderIcon
                    sx={{ width: 34, height: 34, mb: 0.2 }}
                    color="primary"
                  />
                  <Typography sx={{ fontSize: 20, color: "#290C27" }}>
                    {newAccount.likes ? newAccount.likes : "0"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Diversity1Icon
                    sx={{ width: 34, height: 34, mb: 0.2 }}
                    color="primary"
                  />
                  <Typography sx={{ fontSize: 20, color: "#290C27" }}>
                    {newAccount.communities ? newAccount.communities : "0"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ConnectWithoutContactIcon
                    sx={{ width: 34, height: 34, mb: 0.2 }}
                    color="primary"
                  />
                  <Typography sx={{ fontSize: 20, color: "#290C27" }}>
                    {newAccount.connections ? newAccount.connections : "0"}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Divider />
            {/* <Box>
              <SliderComponent tags={usertags} />
            </Box> */}
            <Box sx={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              alignItems: "center"
            }}>
              {/* <SliderComponent tags={usertags} /> */}
              {
                usertags.length > 5 && !fullTags ? (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => { navigate("/search/" + usertags[0]) }}
                    >
                      {usertags[0]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => { navigate("/search/" + usertags[1]) }}
                    >
                      {usertags[1]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => { navigate("/search/" + usertags[2]) }}
                    >
                      {usertags[2]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => { navigate("/search/" + usertags[3]) }}
                    >
                      {usertags[3]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => { navigate("/search/" + usertags[4]) }}
                    >
                      {usertags[4]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => { setFullTags(true) }}
                    >
                      {` + ${usertags.length - 5}`}
                    </Button>
                  </>
                ) : (
                  usertags.map((tag, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => { navigate("/search/" + tag) }}
                    >
                      {tag}
                    </Button>
                  ))
                )
              }
            </Box>
            <Divider />
            <Box
              sx={{
                padding: 1,
                height: 100,
                overflowY: "scroll",
                "&::-webkit-scrollbar": { display: "none" },
              }}
            >
              <Typography color={"#290C27"}>
                {newAccount.description ? newAccount.description : ""}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "74vw" },
            height: { xs: "auto", md: 600 },
            overflowY: { xs: "none", sm: "none", md: "scroll" },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            "&::-webkit-scrollbar": { display: "none" },
            borderLeft: { xs: "none", md: "solid 0.1vw #ADADAD" },
          }}
        >
          <Box
            sx={{
              width: "95%",
            }}
          >
            <Box sx={{ paddingBottom: 6 }}>
              {!load && error === "" && (
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction="row"
                  useFlexGap
                  flexWrap="wrap"
                  sx={{ justifyContent: "center", my: 3 }}
                >
                  {
                    filteredList.length > 0 &&
                    filteredList.map((card, index) => (
                      <CardProfile
                        key={index}
                        card={card}
                        setOpenDetailCard={setOpenDetailCard}
                        OpenCardModal={OpenCardModal}
                        profilePic={newAccount.pic_profile}
                      />
                    ))
                  }
                </Stack>
              )}
              {load && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <Typography>Cargando...</Typography>
                </Box>
              )}
              {error && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 3,
                  }}
                >
                  <Typography>{error}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        component={"img"}
        src={Wave1}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 100,
        }}
      />
      <Box
        component={"img"}
        src={Wave2}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 80,
        }}
      />
      {currentCardModal && (
        <>
          <DetailcardPublicProfile
            openDetailCard={openDetailCard}
            setOpenDetailCard={setOpenDetailCard}
            card={currentCardModal}
          />
        </>
      )}
      {
        profilePictureModal && (
          <Modal
            sx={{
              overflow: "scroll"
            }}
            open={profilePictureModal}
            onClose={() => setProfilePictureModal(false)}
          >
            <Box
              sx={{
                width: { xs: "90vw", md: "60vw" },
                height: { xs: "80vh", md: "80vh" },
                bgcolor: "white",
                position: "relative",
                top: "50%",
                left: "50%",
                transform: {xs: "translate(-50%,-50%)", md: "translate(-50%,-50%)"},
                borderRadius: 2.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                outline: "none",
              }}
            >
              <Box 
                component={"img"}
                src={newAccount.pic_profile}
                sx={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
              />
            </Box>
          </Modal>
        )
      }
    </>
  );
};

export default PublicProfile;
