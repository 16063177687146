import React from "react";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import { Avatar, Box, Divider, Typography } from "@mui/material";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { Pagination, Navigation } from "swiper/modules";

//icons
import HistoryCard from "./HistoryCard";

const AccountHistories = ({
  openHistoryCardsModal,
  setOpenHistoryCardsModal,
  selectedAccount,
}) => {
  const handleClose = () => {
    setOpenHistoryCardsModal(false);
  };

  return (
    <Dialog
      open={openHistoryCardsModal}
      onClose={handleClose}
      maxWidth={"md"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={"body"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 2,
              py: 1,
            }}
          >
            <Box sx={{ m: 1 }}>
              <Avatar
                alt={selectedAccount?.fullname}
                src={selectedAccount?.pic_profile}
                sx={{
                  width: 40,
                  height: 40,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: 14 }}>
                {selectedAccount?.fullname}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{
            p: 1,
            height: 500,
          }}
        >
          {selectedAccount.userCards.length !== 0 ? (
            <Swiper
              pagination={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="historySwiper"
            >
              {selectedAccount.userCards?.map((card, index) => (
                <SwiperSlide key={index}>
                  <Box sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}>
                    <HistoryCard card={card} />
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#ADADAD",
                  fontSize: 15,
                  textAlign: "center",
                  width: "95%",
                }}
              >
                Este usuario no tiene publicaciones en este momento.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AccountHistories;
