import { useLocation, Navigate } from "react-router-dom"
import useAuth from "./useAuth"
import React from 'react'

//Services
import AccountService from "../../services/AccountService";

const RequireAuth = ({ children }) => {
    const { verifyToken, user, logout, saveAccount } = useAuth()
    const token = !user?.accessToken ? null : user.accessToken;
    const accountInfo = localStorage.getItem('hoshii_account')

    let location = useLocation()
    const [auth, setAuth] = React.useState(null)

    // console.log(auth)
    const getAccountData = React.useCallback(async () => {
        try {
            const accountData = await AccountService.getAccount(token)
            if (accountData.code === "EXISTACCOUNT") {
                saveAccount(accountData.account);
                // console.log(accountData)
            } else {
                console.log("Hubo un error en le servicio de cuentaS")
            }
        } catch (error) {
            console.log(error)
        }
    }, [token, saveAccount]);

    // console.log(JSON.parse(accountInfo))

    React.useEffect(() => {
        const verifyAuth = async () => {
            try {
                const authData = await verifyToken()
                // console.log(authData)
                setAuth(authData)
            } catch (error) {
                console.log(error)
                console.log('Error on VerifyToken')
                setAuth(null)
            }
        }
        verifyAuth()
    }, [verifyToken]);

    React.useEffect(() => {
        if (!accountInfo) {
            void getAccountData()
        }
    }, [getAccountData, accountInfo])

    if (!user) {
        logout()
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        // console.log(auth)
        if (
            auth && auth.code === "200" &&
            accountInfo && JSON.parse(accountInfo).active === true &&
            accountInfo && JSON.parse(accountInfo).usertags.length > 0
        ) {
            return children
        } else if (
            auth && auth.code === "200" &&
            accountInfo && JSON.parse(accountInfo).active === false &&
            accountInfo && JSON.parse(accountInfo).usertags.length === 0
        ) {
            return <Navigate to="/register/step/2" state={{ from: location }} replace />
        } else if (
            auth && auth.code === "200" &&
            accountInfo && JSON.parse(accountInfo).active === false &&
            accountInfo && JSON.parse(accountInfo).usertags.length > 0
        ) {
            return <Navigate to="/register/step/3" state={{ from: location }} replace />
        } else if (
            auth && auth.code === "200" &&
            accountInfo && JSON.parse(accountInfo).active === true &&
            accountInfo && JSON.parse(accountInfo).usertags.length === 0
        ) {
            return <Navigate to="/register/step/2" state={{ from: location }} replace />
        }

    }
}

export default RequireAuth