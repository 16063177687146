import React, { useEffect } from "react";
// import SliderComponent from "../components/SliderComponent";
import {
  Box,
  Button,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import AdminService from "../services/AdminService";

//Context
import useAuth from "../context/auth/useAuth";
// import AccountService from "../services/AccountService";
import AccountService from "../services/AccountService";

//icons
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ModalCreateNewMaster from "../components/masters/ModalCreateNewMaster";
import DeleteIcon from '@mui/icons-material/Delete';

//Toast
import { toast } from "react-toastify";
import ModalConfirmDeletecard from "../components/masters/ModalConfirmDeletecard";

const Masters = () => {
  // const [contentType, setContentType] = React.useState(false)
  const { user } = useAuth();
  const token = !user?.accessToken ? null : user.accessToken;
  const [skip, setSkip] = React.useState(0)
  const [limit, setLimit] = React.useState(10)
  const [masterCardList, setMasterCardList] = React.useState([])
  const [selectedMaster, setSelectedMaster] = React.useState(null)
  const [filterValue, setFilterValue] = React.useState("")
  const [openModalCreateNewmaster, setOpenModalCreateNewmaster] = React.useState(false)
  const [openModalDeleteMaster, setOpenModalDeleteMaster] = React.useState(false)

  const [errors, setErrors] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  //Functions

  const fetchMasterCards = React.useCallback(async () => {
    setLoading(true)
    try {
      let masterCards = await AdminService.getMastercards(token, skip, limit)
      // console.log(masterCards.masterCards)
      setMasterCardList(masterCards.masterCards)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setErrors(error)
      setLoading(false)
    }
  }, [limit, skip, token])

  const deleteCard = async (id) => {
    try {
      await AccountService.deleteCard(token, id);
      const newCardsArray = masterCardList.filter((item) => item._id !== id);
      setMasterCardList(newCardsArray);
      toast.success("Se ha eliminado el maestro", { autoClose: 2500 })
      setOpenModalDeleteMaster(false)
      // setFilteredList(newCardsArray);
    } catch (error) {
      console.log(error)
      toast.error("Hubo un error al eliminar el maestro", { autoClose: 2500 })
    }
  };

  React.useEffect(() => {
    if (filterValue !== "") {
      const filtermaster = masterCardList.filter((master) => master.title === filterValue)
      setMasterCardList(filtermaster)
    }
    else{
      fetchMasterCards()
    }
    // if(filterValue && filterValue.length > 3 || filterValue !== ""  && filterValue.length > 3){
    //   const filtermaster = masterCardList.filter((master) => master.title === filterValue)
    //   setMasterCardList(filtermaster)
    // }else{
    //   return
    // }
  }, [filterValue, fetchMasterCards])

  const OpenDeleteModal = (master) => {
    setSelectedMaster(master)
    setOpenModalDeleteMaster(master)
  }

  return (
    <>
      <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
        {
          <Typography
            sx={{
              fontSize: 16,
              color: "red",
              mb: 3,
            }}
          >
            {errors}
          </Typography>
        }
      </Box>
      <Box>
        <Typography sx={{ fontSize: 24, ml: 2 }}>Lista de maestros</Typography>
      </Box>
      <Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: "space-between",
            px: 2,
            mt: 2
          }}
        >
          <TextField
            name="search"
            type="search"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            label="Buscar maestro"
            size="small"
            sx={{
              width: '60%'
            }}
          />
          <Button variant="outlined" startIcon={<AddCircleOutlineIcon />} onClick={() => setOpenModalCreateNewmaster(true)}>
            AGREGAR NUEVO MAESTRO
          </Button>
        </Box>
      </Box>
      <Divider sx={{ my: 3 }} />
      <Box sx={{ px: 2 }}>
        {
          loading && masterCardList.length === 0 && (
            <Box sx={{ width: '100%', my: 8, display: 'flex', justifyContent: 'center' }}>
              <CircularProgress color="primary" size={'4rem'} />
            </Box>
          )
        }
        {
          !loading && masterCardList.length === 0 && (
            <Box sx={{ my: 10 }}>
              <Typography sx={{ fontSize: 24 }} align="center">No hay maestros para mostrar</Typography>
            </Box>
          )
        }
        {
          !loading && masterCardList.length > 0 && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Título</TableCell>
                    <TableCell align="right">Descripción</TableCell>
                    <TableCell align="right">Tags Qty</TableCell>
                    <TableCell align="right">Likes</TableCell>
                    <TableCell align="right">Dislikes</TableCell>
                    <TableCell align="right">Estado</TableCell>
                    <TableCell align="right">Opciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {masterCardList.map((card, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {card.title}
                      </TableCell>
                      <TableCell align="right">{card.description}</TableCell>
                      <TableCell align="right">{card.cardtags.length}</TableCell>
                      <TableCell align="right">{card.likes}</TableCell>
                      <TableCell align="right">{card.dislikes}</TableCell>
                      <TableCell align="right">{card.active ? "Activo" : "Inactivo"}</TableCell>
                      <TableCell align="right"> <IconButton onClick={() => OpenDeleteModal(card)}><DeleteIcon /></IconButton></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )
        }
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
        }}
      >
      </Box>

      <ModalCreateNewMaster
        open={openModalCreateNewmaster}
        setOpen={setOpenModalCreateNewmaster}
        fetchMasterCards={fetchMasterCards}
      />
      {
        selectedMaster && (
          <ModalConfirmDeletecard
            open={openModalDeleteMaster}
            setOpen={setOpenModalDeleteMaster}
            deleteCard={deleteCard}
            selectedMaster={selectedMaster}
          />
        )
      }
    </>
  );
};

export default Masters;
