import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider, Modal, Typography } from "@mui/material";

//navigation
import { useNavigate } from "react-router-dom";

//icons
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import SliderComponent from "../SliderComponent";

const DetailcardPublicProfile = ({
  openDetailCard,
  setOpenDetailCard,
  card,
}) => {

  const [mediaCount, setMediaCount] = React.useState(0);

  const [cardInterface, setCardInterface] = React.useState(true);

  const ChangeMedia = () => {
    if (cardInterface && card.images) {
      if (mediaCount < card.images.length - 1) {
        setMediaCount(mediaCount + 1);
      } else if (mediaCount === card.images.length - 1) {
        setMediaCount(0);
      }
    }
  };

  const handleClose = () => {
    setOpenDetailCard(false);
    setMediaCount(0);
  };

  const [fullTags, setFullTags] = React.useState(false)

  const navigate = useNavigate();

  return (
    <Modal
    open={openDetailCard}
    onClose={() => {
      handleClose()
      setFullTags(false)
    }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{
      overflow: "scroll"
    }}
  >
    <Box
      sx={{
        width: { xs: "100%", md: "60vw" },
        height: { xs: "auto", md: "80vh" },
        bgcolor: "white",
        position: "relative",
        top: {xs: "0", md: "50%"},
        left: "50%",
        transform: {xs: "translateX(-50%)", md: "translate(-50%,-50%)"},
        borderRadius: 2.5,
        boxShadow: "0 0 25px 3px black",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
              <Box
          sx={{
            width: { xs: "100%", md: "50%" },
            height: "auto",
            display: "flex",
            flexWrap: "no-wrap",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            position: "absolute",
            top: 5,
            left: 0,
          }}
        >
          {card.images &&
            card.images.length > 0 &&
            card.images.map((tag, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  height: 2,
                  bgcolor: index === mediaCount ? "#8B4DBA" : "white",
                  borderRadius: 10,
                  mx: 1,
                }}
              />
            ))}
        </Box>
      <Box
        component={"div"}
        alt="image card"
        onClick={() => {
          ChangeMedia();
        }}
        sx={{
          width: { xs: "100%", md: "50%" },
          height: { xs: 550, md: "auto" },
          backgroundImage: `url(${card.images.length > 0? card.images[mediaCount].url : card.cardImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          boxShadow: "10px 0 25px 3px #ADADAD",
          borderRadius: "0.6vw 0 0 0.6vw",
        }}
      />
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "black",
            width: "100%",
            textAlign: "center",
            fontSize: 20,
            my: 2.5,
          }}
        >
          {card.title}
        </Typography>
        <Box
          sx={{
            width: "95%",
            height: "0.05vw",
            bgcolor: "#ADADAD",
          }}
        />
        <Box
          sx={{
            width: { xs: "100%", md: "100%" },
            height: "auto",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            my: 1,
          }}
        >
          {card.cardtags.length > 5 && !fullTags ? (
            <>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {
                  navigate("/search/" + card.cardtags[0]);
                }}
              >
                {card.cardtags[0]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {
                  navigate("/search/" + card.cardtags[1]);
                }}
              >
                {card.cardtags[1]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {
                  navigate("/search/" + card.cardtags[2]);
                }}
              >
                {card.cardtags[2]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {
                  navigate("/search/" + card.cardtags[3]);
                }}
              >
                {card.cardtags[3]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {
                  navigate("/search/" + card.cardtags[4]);
                }}
              >
                {card.cardtags[4]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {setFullTags(true)}}
              >
                {` + ${card.cardtags.length - 5}`}
              </Button>
            </>
          ) : (
            card.cardtags.map((tag, index) => (
              <Button
                key={index}
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {
                  navigate("/search/" + tag);
                }}
              >
                {tag}
              </Button>
            ))
          )}
        </Box>
        <Box
          sx={{
            width: "95%",
            height: "0.05vw",
            bgcolor: "#ADADAD",
          }}
        />
        <Typography
          sx={{
            width: "92.5%",
            mt: 1,
            mb: {xs: 10, md: 0},
            overflowX: "hidden",
          }}
        >
          {card.description}
        </Typography>
        <DialogActions sx={{
          position: "absolute",
          bottom: 0,
          right: 0
        }}>
          <Button onClick={() => {
            handleClose()
            setFullTags(false)
          }}>cancelar</Button>
        </DialogActions>
      </Box>
    </Box>
  </Modal>
  )
}

export default DetailcardPublicProfile

{/* <div>
<Dialog
  open={openDetailCard}
  onClose={() => {
    handleClose()
    setFullTags(false)
  }}
  sx={{ width: "100%" }}
  maxWidth={false}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
  scroll={"body"}
>
  <Box
    sx={{
      display: "flex",
      flexDirection: { xs: "column", sm: "column", md: "row" },
      width: { xs: "100%", md: "50vw" }
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: { xs: "100%", sm: "100%", md: "45%" },
        marginY: 2,
        borderRight: { xs: "none", md: "solid 0.1vw #ADADAD" },
      }}
    >
      <Box
        sx={{
          width: { xs: 350, md: "95%" },
          height: { xs: 350, sm: 400, md: 500 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ml: 2,
          mr: 2,
          overflowX: 'hidden'
        }}
      >

        <Box
          component={"img"}
          alt="image card"
          src={
            card.cardImg
              ? card.cardImg
              : "https://res.cloudinary.com/dujeh3ms8/image/upload/v1668012395/tokomarket_uploads_company_adds/na4ofxui5ui4mhfo5i4r.jpg"
          }
          sx={{ height: '100%' }}
        />

      </Box>
    </Box>
    <Box
      sx={{
        width: { xs: "100%", sm: "100%", md: "55%" },
      }}
    >
      <DialogTitle id="alert-dialog-title">{card.title}</DialogTitle>
      <DialogContent>
        <Divider />
        <Box
          sx={{
            width: { xs: "100%", md: "100%" },
            height: "auto",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
           {
          card.cardtags.length > 5 && !fullTags ? (
            <>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {navigate("/search/" + card.cardtags[0])}}
              >
                {card.cardtags[0]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {navigate("/search/" + card.cardtags[1])}}
              >
                {card.cardtags[1]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {navigate("/search/" + card.cardtags[2])}}
              >
                {card.cardtags[2]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {navigate("/search/" + card.cardtags[3])}}
              >
                {card.cardtags[3]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {navigate("/search/" + card.cardtags[4])}}
              >
                {card.cardtags[4]}
              </Button>
              <Button
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {setFullTags(true)}}
              >
                {` + ${card.cardtags.length - 5}`}
              </Button>
            </>
          ) : (
            card.cardtags.map((tag, index) => (
              <Button
                key={index}
                variant="contained"
                sx={{
                  margin: 1,
                }}
                onClick={() => {navigate("/search/" + tag)}}
              >
                {tag}
              </Button>
            ))
          )
        }
        </Box>
        <Divider />
        <DialogContentText
            sx={{
              marginY: 2,
              width: "100%",
              overflowX: "hidden"
            }}
          id="alert-dialog-description"
        >
          {card.description}
        </DialogContentText>
      </DialogContent>
    </Box>
  </Box>
  <DialogActions>
    <Button onClick={() => {
      handleClose()
      setFullTags(false)
    }}>cerrar</Button>
  </DialogActions>
</Dialog>
</div> */}