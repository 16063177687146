import server from "../settings/config"

const AuthService = {}
// const SIGNIN_API_URL = `${server.urlServer}/api/auth/user/signin`
const SIGNIN_API_URL = `${server.urlServer}/api/auth/v2/signin`
const SIGNUP_API_URL = `${server.urlServer}/api/auth/v2/signup`
const CHECK_EMAIL_URL = `${server.urlServer}/api/auth/user/verifyemailsignup`
const RECOVER_PASSWORD_URL = `${server.urlServer}/api/auth/recovery-password`
const RESTORE_PASSWORD_URL = `${server.urlServer}/api/auth/change-password`


const SIGNUP_V2_API_URL = `${server.urlServer}/api/auth/v2/signup`
const SIGNUP_STEP2_V2_API_URL = `${server.urlServer}/api/auth/v2/signup/step-two`


AuthService.signIn = async (formData) => {

    const response = await fetch(SIGNIN_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    });
    // console.log(response)
    if (!response.ok) {
        const error = await response.json()
        console.log(error)
        throw new Error(error.message);
    }

    return await response.json();

} 

AuthService.signUp = async (formData) => {
    let signUp = await fetch(SIGNUP_API_URL,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(formData)
    })
    if(!signUp.ok){
        let response = {
            code: signUp.status,
            msg: signUp.statusText
        }
        return response
    }
    let data = await signUp.json()

    let response = {
        code: signUp.status,
        user: data,
        msg: data.msg
    }
    return response
}

AuthService.signUpV2 = async (formData) => {
    const response = await fetch(SIGNUP_V2_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    });
    // console.log(response)
    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.message);
    }

    return await response.json();
}

AuthService.SignUpStepTwo = async (formData, token) => {
    const response = await fetch(SIGNUP_STEP2_V2_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization":`Bearer ${token}`
        },
        body: JSON.stringify(formData)
    });
    // console.log(response)
    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.message);
    }

    return await response.json();
}

AuthService.checkEmail = async (formData) => {
    const response = await fetch(CHECK_EMAIL_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    });
    // console.log(response)
    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.message);
    }

    return await response.json();

} 

AuthService.recoverPassword = async (formData) => {
    const response = await fetch(RECOVER_PASSWORD_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    });
    // console.log(response)
    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.message);
    }

    return await response.json();
} 

AuthService.restorePassword = async (formData) => {
    const response = await fetch(RESTORE_PASSWORD_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    });
    // console.log(response)
    if (!response.ok) {
        const error = await response.json()
        // console.log(error)
        throw new Error(error.message);
    }

    return await response.json();
} 

export default AuthService