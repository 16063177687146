import server from '../settings/config'

const HomeService = {}

const GET_CARDS_URL_API = `${server.urlServer}/api/home/get-cards`
const GET_ACCOUNTS_URL_API = `${server.urlServer}/api/home/get-accounts`
const GET_MASTERS_URL_API = `${server.urlServer}/api/masters/get-master`
const GET_ADS_URL_API = `${server.urlServer}/api/masters/get-ad`
const LIKE_ACCOUNT_URL_API = `${server.urlServer}/api/home/like-user`
const DISLIKE_ACCOUNT_URL_API = `${server.urlServer}/api/home/user-dislike`
const LIKECARD_ACCOUNT_URL_API = `${server.urlServer}/api/home/like-card`
const DISLIKECARD_ACCOUNT_URL_API = `${server.urlServer}/api/home/dislikelike-card`
const LIKE_MASTER_URL_API = `${server.urlServer}/api/home/master-like`
const DISLIKE_MASTER_URL_API = `${server.urlServer}/api/home/dislike-master`
const ADD_TAGS_PROFILE_URL_API = `${server.urlServer}/api/account/add-tags`
const LIKE_AD_URL_API = `${server.urlServer}/api/home/like-dislike-ads`
const DISLIKE_AD_URL_API = `${server.urlServer}/api/home/like-dislike-ads`
const NEUTRAL_URL_API = `${server.urlServer}/api/home/not-interested`


HomeService.getCards = async (token) => {
    let getcards = await fetch(GET_CARDS_URL_API,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    if(!getcards.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await getcards.json()

    let response = {
        code: data.code,
        cards: data.cards
    }
    return response
}

HomeService.getMasters = async (token) => {
    let getcards = await fetch(GET_MASTERS_URL_API,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    if(!getcards.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await getcards.json()

    let response = {
        code: data.code,
        cards: data.fetchcards,
        counts: data.counts
    }
    return response
}

HomeService.getAds = async (token) => {
    let getcards = await fetch(GET_ADS_URL_API,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    if(!getcards.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await getcards.json()

    let response = {
        code: data.code,
        cards: data.fetchcards,
        counts: data.counts
    }
    return response
}

HomeService.getAccounts =async (token) => {
    let getaccounts = await fetch(GET_ACCOUNTS_URL_API,{
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
    })
    if(!getaccounts.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await getaccounts.json()

    let response = {
        code: data.code,
        cards: data.fetchAccounts,
        counts: data.counts
    }
    return response
}

HomeService.likeAccount = async (formData, token) => {
    let likeAccount = await fetch(LIKE_ACCOUNT_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!likeAccount.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await likeAccount.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.dislikeAccount = async (formData, token) => {
    let dislikeAccount = await fetch(DISLIKE_ACCOUNT_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!dislikeAccount.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await dislikeAccount.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.likeCard = async (formData, token) => {
    let likeCard = await fetch(LIKECARD_ACCOUNT_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!likeCard.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await likeCard.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.dislikeCard = async (formData, token) => {
    let dislikeCard = await fetch(DISLIKECARD_ACCOUNT_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!dislikeCard.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await dislikeCard.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.likeMaster = async (formData, token) => {
    let likeMaster = await fetch(LIKE_MASTER_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!likeMaster.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await likeMaster.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.dislikeMaster = async (formData, token) => {
    let dislikeMaster = await fetch(DISLIKE_MASTER_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!dislikeMaster.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await dislikeMaster.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.addProfiletags = async (formData, token) => {
    let addTags = await fetch(ADD_TAGS_PROFILE_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!addTags.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await addTags.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.likeAd = async(formData, token) => {
    let likeAd = await fetch(LIKE_AD_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!likeAd.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await likeAd.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.dislikeAd = async(formData, token) => {
    let dislikeAd = await fetch(DISLIKE_AD_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!dislikeAd.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await dislikeAd.json()

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

HomeService.NeutralSwipe = async (formData, token) => {
    let likeCard = await fetch(NEUTRAL_URL_API,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData)
    })
    if(!likeCard.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }
    let data = await likeCard.json()

    console.log(data)

    let response = {
        code: data.code,
        msg: data.msg
    }
    return response
}

export default HomeService