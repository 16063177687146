export const getSender = (loggedUser, users) => {
    // console.log(users)
    // console.log(loggedUser)
    if(loggedUser && users) return users[0]._id === loggedUser._id ? users[1].name : users[0].name;
}

export const getSenderFull = (loggedUser, users) => {
    return users[0]._id === loggedUser._id ? users[1] : users[0];
}

export const isSameSender = (messages, m, i, userId) => {
    let response = i < messages.length - 1 &&
    (messages[i + 1].sender._id !== m.sender._id ||
        messages[i + 1].sender._id === undefined) &&
        messages[i].sender._id !== userId

    // console.log(response)
    return response
}

export const isLastMessage = (messages, i, userId) => {
    let response = i === messages.length - 1 &&
    messages[messages.length -1].sender._id !== userId &&
    messages[messages.length -1].sender._id

    // console.log(response)
    return response
}

export const isSameSenderMargin = (messages, m, i, userId) => {
    if(
        i < messages.length - 1 &&
        messages[i + 1].sender._id === m.sender._id &&
        messages[i].sender._id !== userId
    
    ) return 33

    else if
    (
        (i < messages.length - 1 &&
            messages[i + 1].sender._id !== m.sender._id &&
            messages[i].sender._id !== userId) ||
        (i === messages.length - 1 && messages[i].sender._id !== userId)
    )return 0
    else return "auto"
    
}

export const isSameUser = (messages, m, i) => {
    return i > 0 && messages[i - 1].sender._id === m.sender._id
}