import React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box } from "@mui/material";


const ChoiceBoxMaster = ({ handlerCardLike, handlerMasterDislike, handlerNeutralMaster }) => {

  const LikeSwipe = () => {
    handlerCardLike()
  };

  const DislikeSwipe = () => {
    handlerMasterDislike()
  };

  const NeutralSwipe = () => {
    handlerNeutralMaster()
  };

  return (
    <Box
      sx={{
        width: 350,
        height: 80,
        position: "fixed",
        bottom: 30,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        zIndex: 101,
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Box
        onClick={() => {
          DislikeSwipe();
        }}
        sx={{
          width: 65,
          height: 65,
          borderRadius: "50%",
          boxShadow: "10px 10px 53px -14px rgba(0,0,0,0.75)",
          bgcolor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <HeartBrokenIcon sx={{ color: "#ADADAD", fontSize: 30 }} />
      </Box>
      <Box
        onClick={() => {
          NeutralSwipe();
        }}
        sx={{
          width: 65,
          height: 65,
          borderRadius: "50%",
          boxShadow: "10px 10px 53px -14px rgba(0,0,0,0.75)",
          bgcolor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <CachedIcon sx={{ color: "#ADADAD", fontSize: 30 }} />
      </Box>
      <Box
        onClick={() => {
          LikeSwipe();
        }}
        sx={{
          width: 65,
          height: 65,
          borderRadius: "50%",
          boxShadow: "10px 10px 53px -14px rgba(0,0,0,0.75)",
          bgcolor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <FavoriteIcon sx={{ color: "#8B4DBA", fontSize: 30 }} />
      </Box>
    </Box>
  );
};

export default ChoiceBoxMaster;
