import config from '../settings/config'
const TrendingService = {}
const GET_CARD_TRENDING_URL_API = `${config.urlServer}/api/trending/get-cards`

TrendingService.getCards = async (token, tag) => {
    if(token){
        const response = await fetch(`${GET_CARD_TRENDING_URL_API}/${tag}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if(!response.ok) return new Error('Something went wrong!, please try again');
        const data = await response.json()
        return data
    }else{
        throw new Error("No Authorize")
    }
}

export default TrendingService