import React, { useState } from "react";

//Waves
import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import Header from "../components/Header";
import TrendingSwipeCard from "../components/trending/TrendingSwipeCard";
import TrendingChoiceBox from "../components/trending/TrendingChoiceBox";

import FavoriteIcon from '@mui/icons-material/Favorite';

//services
import TagService from "../services/TagService";
import HomeService from "../services/HomeService";
import TrendingService from "../services/TrendingService";

//navigation
import { useNavigate } from "react-router-dom";

//auth
import useAuth from "../context/auth/useAuth";
import HomeLoader from "../components/home/HomeLoader";
import { TabContext, TabPanel } from "@mui/lab";

const Trending = () => {
  const { user } = useAuth();
  const userData = user;
  const token = userData?.accessToken ? userData.accessToken : null;
  // const [errors, setErrors] = React.useState(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = React.useState(null);
  const [tagList, setTagList] = React.useState([]);
  const [selectedTag, setSelectedTag] = React.useState(null);

  const [cardList, setCardList] = useState([]);
  const [selectedcard, setSelectedcard] = React.useState(null);
  const [warning, setWarning] = useState("");
  const [mainContentDone, setMainContentDone] = useState(false);
  const [loading, setloading] = useState(false);

  const [trendingTab, setTrendingTab] = React.useState("1");

  const [fullTags, setFullTags] = useState(false)

  const handleChange = (event, newValue) => {
    setTrendingTab(newValue);
  };

  // console.log(tagList)

  const navigate = useNavigate();

  const removeCardList = (selectionCard) => {
    if (cardList.length > 0) {
      const newCardsList = cardList.filter(
        (card) => card._id !== selectionCard._id
      );
      setCardList(newCardsList);
      setSelectedcard(newCardsList[0]);
    }
  };

  const handlerCardLike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        userLike: selectedcard.accountId ? selectedcard.accountId._id : null,
        cardId: selectedcard._id,
      };

      try {
        const likeCard = await HomeService.likeCard(formData, token);
        if (likeCard.code !== "200") {
          console.log(likeCard.code, likeCard.msg);
          setWarning(likeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para eliminar una card de la lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlerCardDislike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        userdislike: selectedcard.accountId ? selectedcard.accountId._id : null,
        cardId: selectedcard._id,
      };

      try {
        const dislikeCard = await HomeService.dislikeCard(formData, token);
        // console.log(dislikeCard);
        if (dislikeCard.code !== "200") {
          console.log(dislikeCard.code, dislikeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para quitar la card de lla lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const fetchCards = React.useCallback(async () => {
    setMainContentDone(true);
    setloading(true);

    try {
      const fetchCards = await TrendingService.getCards(token, selectedTag);
      if (fetchCards.cards.length === 0) {
        setMainContentDone(false);
        setloading(false);
        setWarning("No hay mas cards para mostrar");
      } else {
        setCardList(fetchCards.cards);
        setMainContentDone(false);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setWarning("Hubo un error al traer las cards");
    }
  }, [token, selectedTag]);

  const FindTagsByTag = React.useCallback(async () => {
    if (search && search.length >= 3) {
      try {
        const findtags = await TagService.findTagsByTag(token, search);
        // console.log(findtags.tags)
        setTagList(findtags.tags);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const fetchAllTags = await TagService.findAllTags(token);
        // console.log(fetchAllTags.tags)
        setTagList(fetchAllTags.tags);
      } catch (error) {
        console.log(error);
        setWarning(error.message)
      }
    }
  }, [search, token]);

  const setSelectionCard = React.useCallback(() => {
    setFullTags(false);
    if (!selectedcard && cardList.length !== 0) {
      setSelectedcard(cardList[0]);
    }
  }, [cardList, selectedcard]);

  React.useEffect(() => {
    if (cardList.length === 0) {
      void fetchCards();
    } else {
      setSelectionCard();
    }
  }, [fetchCards, cardList.length, setSelectionCard]);

  React.useEffect(() => {
    FindTagsByTag();
  }, [FindTagsByTag]);

  return (
    <>
      <Header />
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: { xs: "40%", md: "80%" },
          top: 0,
          zIndex: 210,
          height: 50,
          left: "50%",
          transform: "translateX(-50%)",
          transition: "0.3s ease",
        }}
      >
        <TextField
          id="standard-basic"
          label="Buscar..."
          variant="standard"
          fullWidth={true}
          onChange={(e) => {
            e.target.value !== "" ? setSearchOpen(true) : setSearchOpen(false);
            setSearch(e.target.value.toLowerCase());
          }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: searchOpen ? "auto" : 0,
          bgcolor: "white",
          overflowY: "scroll",
          zIndex: 200,
          display: "flex",
          flexDirection: "column",
          transition: "0.3s ease",
          overflowX: "hidden",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {tagList.length > 0 ? (
          tagList.map((tag, index) => (
            <Button
              key={index}
              variant="outlined"
              sx={{
                margin: 1,
              }}
              onClick={() => {
                navigate("/search/" + tag.name);
              }}
            >
              {tag.name}
            </Button>
          ))
        ) : (
          <Typography
            sx={{
              width: { xs: "50%", md: "100%" },
              textAlign: "center",
              m: 5,
            }}
          >
            No se ha encontrado nada relacionado a tu busqueda
          </Typography>
        )}
      </Box>
      <Box
        component={"main"}
        sx={{
          width: "100%",
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "relative",
          zIndex: 99,
          mb: 15,
        }}
      >
        <TabContext value={trendingTab}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Tabs
              value={trendingTab}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="Trending navigation"
            >
              <Tab value="1" label="Tendencias" />
              <Tab value="2" label="Swipe" />
            </Tabs>
          </Box>
          <TabPanel value="1">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                zIndex: 99,
                mb: 15,
                mt: 2
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                {tagList.length > 0 &&
                  tagList
                    .sort(function (a, b) {
                      if (a.count > b.count) {
                        return 1;
                      }
                      if (a.count < b.count) {
                        return -1;
                      }
                      // a must be equal to b
                      return 0;
                    })
                    .reverse()
                    .map((tag, index) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        key={index}
                      >
                        <Avatar sx={{
                          bgcolor: "#8B4DBA",
                          width: 30,
                          height: 30,
                          fontSize: 15
                        }}>{index + 1}</Avatar>
                        <Button
                          key={index}
                          variant="outlined"
                          sx={{
                            margin: 1,
                          }}
                          onClick={() => {
                            navigate("/search/" + tag.name);
                            setSelectedTag(tag.name);
                          }}
                        >
                          {tag.name}
                        </Button>
                          <FavoriteIcon sx={{
                            color: "#B480DA"
                          }}/>
                          <Typography sx={{
                            fontSize: 15
                          }}>{tag.count}</Typography>
                      </Box>
                    ))}
              </Box>
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{
            padding: 0,
            width: "100%"
          }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
                zIndex: 99,
                mb: 15,
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  mt: { xs: 2, md: 3 },
                }}
              >
                {mainContentDone && loading && <HomeLoader />}
                {!mainContentDone && !loading && warning !== "" && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "90vh",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Alert severity="info" sx={{ width: "70%" }}>
                      {warning}
                    </Alert>
                  </Box>
                )}
                {!mainContentDone && selectedcard && (
                  <TrendingSwipeCard
                    card={selectedcard}
                    likeCard={handlerCardLike}
                    dislikeCard={handlerCardDislike}
                  />
                )}
              </Box>
              {!mainContentDone && !loading && (
                <Box
                  sx={{
                    width: { xs: "98vw", md: 400 },
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: -3,
                  }}
                >
                  {selectedcard?.cardtags.length > 5 && !fullTags ? (
                    <>
                      <Button
                        variant="contained"
                        sx={{
                          margin: 1,
                        }}
                        onClick={() => {
                          navigate("/search/" + selectedcard.cardtags[0]);
                        }}
                      >
                        {selectedcard.cardtags[0]}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          margin: 1,
                        }}
                        onClick={() => {
                          navigate("/search/" + selectedcard.cardtags[1]);
                        }}
                      >
                        {selectedcard.cardtags[1]}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          margin: 1,
                        }}
                        onClick={() => {
                          navigate("/search/" + selectedcard.cardtags[2]);
                        }}
                      >
                        {selectedcard.cardtags[2]}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          margin: 1,
                        }}
                        onClick={() => {
                          navigate("/search/" + selectedcard.cardtags[3]);
                        }}
                      >
                        {selectedcard.cardtags[3]}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          margin: 1,
                        }}
                        onClick={() => {
                          navigate("/search/" + selectedcard.cardtags[4]);
                        }}
                      >
                        {selectedcard.cardtags[4]}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          margin: 1,
                        }}
                        onClick={() => {
                          setFullTags(true);
                        }}
                      >
                        {` + ${selectedcard.cardtags.length - 5}`}
                      </Button>
                    </>
                  ) : (
                    selectedcard?.cardtags.map((tag, index) => (
                      <Button
                        key={index}
                        variant="contained"
                        sx={{
                          margin: 1,
                        }}
                        onClick={() => {
                          navigate("/search/" + tag);
                        }}
                      >
                        {tag}
                      </Button>
                    ))
                  )}
                </Box>
              )}

              {!mainContentDone && selectedcard && (
                <Box
                  sx={{
                    width: { xs: "95vw", md: 400 },
                    py: 2,
                    borderTop: "0.1vw solid #ADADAD",
                    borderBottom: "0.1vw solid #ADADAD",
                    mt: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      width: "95%",
                      overflowX: "hidden",
                    }}
                  >
                    {selectedcard?.description}
                  </Typography>
                </Box>
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
      {warning === "" && trendingTab === "2" && (
        <TrendingChoiceBox
          likeCard={handlerCardLike}
          dislikeCard={handlerCardDislike}
        />
      )}
      <Box
        component={"img"}
        src={Wave1}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 110,
        }}
      />
      <Box
        component={"img"}
        src={Wave2}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 80,
        }}
      />
    </>
  );
};

export default Trending;
