import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ModalConfirmDeletecard = (props) => {

    const handleClose = () => {
      props.setOpen(false);
    };

    const deleteCard = () => {
        props.deleteCard(props.selectedMaster._id)
    }
  
    return (
      <React.Fragment>
        <Dialog
          open={props.open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"¡Está seguro que desea eliminar la card?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Al realizar esta acción se eliminará el elemento seleccionado
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => deleteCard()} autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
}

export default ModalConfirmDeletecard