import React from "react";
import Waves from "../assets/images/waves.svg";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
    Alert,
    Avatar,
    Button,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../context/auth/useAuth";

//Services
import AccountService from "../services/AccountService";
import AuthService from "../services/AuthService";

const likesList = ["comida", "deporte", "lectura", "videojuegos", "cocinar", "escribir", "fotografia", "crecimiento personal", "espiritualidad", "arte", "ecologismo", "moda", "viajes", "voluntariado", "peliculas", "series", "autocuidado", "música", "actividad al aire libre", "juegos de mesa", "carros", "motos", "animales", "plantas", "danza", "teatro", "meditacion", "yoga", "pintura", "escultura", "poesia", "ciencia ficcion", "fantasia", "terror", "romance", "humor", "historia", "filosofia", "psicologia", "matematicas", "fisica", "quimica", "biologia", "astronomia", "geografia",
    "arquitectura",
    "diseno",
    "tecnologia",
    "emprendimiento",
    "liderazgo",
    "innovacion",
    "negocios",
    "finanzas",
    "gimnasio",
    "crosfit",
    "ajedrez",
    "marketing",
    "publicidad",
    "comunicacion",
    "periodismo",
    "educacion",
    "investigacion",
    "salud",
    "nutricion",
    "deportes extremos",
    "artes marciales",
    "natacion",
    "ciclismo",
    "senderismo",
    "escalada en roca",
    "esqui",
    "programacion",
    "desarrollo web",
    "bases de datos",
    "inteligencia artificial",
    "aprendizaje automatico",
    "redes neuronales",
    "ciberseguridad",
    "criptografia",
    "blockchain",
    "realidad virtual",
    "realidad aumentada",
    "robótica",
    "automatizacion",
    "domotica",
    "energias renovables",
    "sostenibilidad",
    "economia circular",
    "turismo rural",
    "gastronomía local",
    "vinos y licores artesanales",
    "moda sostenible",
    "belleza natural",
    "bienestar emocional",
    "sexo",
    "costplay"];

const initialData = {
    tags: []
}

const RegisterStepTwo = () => {
    const navigate = useNavigate();
    const { user, saveAccount } = useAuth();
    const token = !user?.accessToken ? null : user.accessToken;

    // const [formats, setFormats] = React.useState(() => []);
    const [formData, setFormData] = React.useState(initialData)
    const [errors, setErrors] = React.useState({})
    const [searchBar, setSearchBar] = useState("");

    // console.log(formData)

    const addTagsForms = (event, tag) => {

        setFormData((formData) => {
            return {
                ...formData,
                tags: []
            }
        })


        setFormData((formData) => {
            return {
                ...formData,
                tags: [...formData.tags, ...tag]
            }
        })
    }

    const TagsFilter = () => {

        const newTagsArray = likesList.filter(tag => tag.toUpperCase().includes(searchBar.toUpperCase()))
        return newTagsArray

    }

    const getAccountData = async () => {
        setErrors({})
        try {
            const accountData = await AccountService.getAccount(token)
            saveAccount(accountData.account);
        } catch (error) {
            // console.log(error)
            setErrors({ formError: error.message })
        }
    }

    const SendTagsData = async () => {

        if (formData.tags.length < 5) return setErrors({ formError: "Debes seleccionar al menos 5 tags" })

        try {
            await AuthService.SignUpStepTwo(formData, token);
            // console.log(addTags);
            await getAccountData()
            navigate("/register/step/3");
        } catch (error) {
            // console.log(error);
            setErrors({ formError: error.message })
        }

    };

    return (
        <>
            <Container component="main" maxWidth="xs" sx={{ zIndex: 100, mb: 20 }}>
                <Box
                    component={"div"}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: 'column'
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={() => {
                            SendTagsData();
                        }}
                        disabled={formData.tags.length >= 5 ? false : true}
                        sx={{
                            zIndex: 100,
                            mt: { xs: 5, md: 5 },
                            mb: { xs: 0, md: 0, lg: 0 },
                        }}
                    >
                        {formData.tags.length >= 5
                            ? "Continuar"
                            : "Selecciona al menos 5 gustos para continuar"}
                    </Button>

                    <Box sx={{my:1}}>
                        {
                            errors.formError && <Alert severity="error">{errors.formError}</Alert>
                        }
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2
                        }}
                    >
                        <TextField
                            type="search"
                            label="buscar..."
                            sx={{ width: "90%", mt: 2.5, mb: 2.5, mr: 1 }}
                            value={searchBar}
                            onChange={(e) => setSearchBar(e.target.value)}
                        />
                        <Avatar sx={{ bgcolor: "#8B4DBA" }}>{formData.tags.length}</Avatar>
                    </Box>
                </Box>
                <ToggleButtonGroup
                    value={formData.tags}
                    onChange={addTagsForms}
                    aria-label="text formatting"
                    // orientation="vertical"
                    sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    {
                        TagsFilter().map((tag, index) => (
                            <ToggleButton
                                key={index}
                                value={tag}
                                aria-label={tag}
                                color="primary"
                                sx={{ m: 1.5, height: 50 }}
                            >
                                {tag}
                            </ToggleButton>
                        ))
                    }
                </ToggleButtonGroup>
            </Container>
            <Box
                component={"img"}
                src={Waves}
                sx={{ width: "100%", bottom: 0, position: "fixed" }}
            />
        </>
    );
};

export default RegisterStepTwo;
