import config from '../settings/config'

const GET_ACCOUNT_URL_API = `${config.urlServer}/api/account/get-user`
const UPDATE_ACCOUNT_FULLNAME_URL_API = `${config.urlServer}/api/account/update-fullname`
const UPDATE_ACCOUNT_DESCRIPTION_URL_API = `${config.urlServer}/api/account/update-description`
const GET_ACCOUNT_CARDS_URL_API = `${config.urlServer}/api/account/get-cards`
const DELETE_ACCOUNT_CARD_URL_API = `${config.urlServer}/api/account/delete-card/`
const UPDATE_IMAGE_PROFILE_URL_API = `${config.urlServer}/api/account/update-img`
const UPDATE_IMAGE_CREATE_URL_API = `${config.urlServer}/api/account/mobile-create-card`
const CREATE_PROFILE_CARD_URL_API = `${config.urlServer}/api/account/modify-mobile-create-card`
const CREATE_CARD_URL_API = `${config.urlServer}/api/account/create-card`
const GET_PUBLIC_ACCOUNT_URL_API = `${config.urlServer}/api/account/findone-account`
const UPDATE_ACCOUNT_SOCIAL_MEDIA_URL_API = `${config.urlServer}/api/account/update-social-media`

const AccountService = {}
//Authenticate

//Profile
AccountService.getAccount = async(token) => {
    if(token){
        const response = await fetch(GET_ACCOUNT_URL_API, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        if(!response.ok) return new Error('Something went wrong!, please try again');
        const data = await response.json()
        return data
    }else{
        throw new Error("No Authorize")
    }
}

AccountService.updateFullname = async(token, fullname) => {
    let updatedFullname = {
        fullname: fullname
    }
    if(token){
        const response = await fetch(UPDATE_ACCOUNT_FULLNAME_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedFullname)
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.updateDescription = async(token, description) => {
    let updatedDescription = {
        description: description
    }
    if(token){
        const response = await fetch(UPDATE_ACCOUNT_DESCRIPTION_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedDescription)
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.getPublicAccountData = async(token, id) => {

    if(token){
        const response = await fetch(GET_PUBLIC_ACCOUNT_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({accountId: id})
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.CreateCardInfo = async(token, item) => {

    if(token){
        const response = await fetch(CREATE_PROFILE_CARD_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item)
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.createCard = async(card, token) => {

    if(token){
        const response = await fetch(CREATE_CARD_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: card
        })
        // console.log(response)
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.updateImageProfile = async(token, formData) => {
    if(token){
        const response = await fetch(UPDATE_IMAGE_PROFILE_URL_API, {
            method: "POST",
            headers:{
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.updateImageCreate = async(token, formData) => {
    if(token){
        const response = await fetch(UPDATE_IMAGE_CREATE_URL_API, {
            method: "POST",
            headers:{
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        })
        const data = await response
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.getUserCardsData = async (token) => {

    if(token){
        const response = await fetch(GET_ACCOUNT_CARDS_URL_API, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.deleteCard = async(token, id) => {

    if(token){
        const response = await fetch(DELETE_ACCOUNT_CARD_URL_API + id, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}

AccountService.updateSocialMedia = async(token, socialMedias) => {
    if(token){
        const response = await fetch(UPDATE_ACCOUNT_SOCIAL_MEDIA_URL_API, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({socialMedia: socialMedias})
        })
        const data = await response.json()
        return data
    }else{
        return new Error("No Authorize")
    }
}


export default AccountService