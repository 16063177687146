import React from "react";
import Waves from "../assets/images/waves.svg";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
    Alert,
    Avatar,
    Button,
    CircularProgress,
    TextareaAutosize,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../context/auth/useAuth";
import AccountService from "../services/AccountService";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

const initialData = {
    fullname: "",
    description: "",
    email: "",
    phone: "",
    gender: "",
    country: "",
    department: "",
    municipality: "",
}

const RegisterStepThree = () => {

    const navigate = useNavigate();
    const { user, saveAccount } = useAuth();
    const token = !user?.accessToken ? null : user.accessToken;

    const [activeAccount, setActiveAccount] = useState(false)
    const uploadForm = React.useRef(null);

    const [selectedImage, setSelectedImage] = useState("");
    const [settingInfo, setSettingsInfo] = React.useState(initialData)
    const [errors, setErrors] = React.useState({});

    const [load, setLoad] = useState(false)
    const [loadImage, setLoadImage] = React.useState(false)

    const [buttonsLoad, setButtonsLoad] = useState({
        fullname: false,
        description: false,
        userInfoForm: false,
        newPassword: false,
        delete: false,
        image: false
    })

    const getAccountData = React.useCallback(async () => {
        setLoad(true)
        try {
            const accountData = await AccountService.getAccount(token)
            // console.log(accountData)
            if (accountData.code === "EXISTACCOUNT") {
                // setNewAccount(res.account);
                setLoad(false)
                let infoData = accountData.account
                saveAccount(accountData.account)
                setActiveAccount(accountData.account.active)
                setSettingsInfo((info) => {
                    return {
                        ...info,
                        infoData
                    }
                })
                setSelectedImage(accountData.account.pic_profile);
            } else {
                console.log(accountData.msg);
                setLoad(false)
            }
        } catch (error) {
            console.log(error)
            setLoad(false)
            setErrors({ formError: error.message })
            // setErrors(error.message)
        }

    }, [token, saveAccount]);

    const handleChangeData = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setSettingsInfo(settingInfo => {
            return {
                ...settingInfo,
                [name]: value
            }
        })
    };

    const handleSubmitDesc = async (e) => {
        setButtonsLoad({ ...buttonsLoad, description: true });

        try {

            if (settingInfo.description !== "") {
                const data = await AccountService.updateDescription(token, settingInfo.description);

                if (data.code === "UPDATEDESCRIPTION") {
                    await getAccountData()
                    setButtonsLoad({ ...buttonsLoad, description: false });
                    toast.success("La descripcion se ha actualizado correctamente", 1500);
                    if (selectedImage !== "https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png" && settingInfo.description !== null && settingInfo.description !== "" && selectedImage !== "") {

                        setActiveAccount(true)

                    }
                } else {

                    setButtonsLoad({ ...buttonsLoad, description: false });
                    toast.error(data.msg, 1500);

                }
            } else {

                setButtonsLoad({ ...buttonsLoad, description: false });
                toast.error("Debes indicar la descripcion a actualizar", 1500);

            }
        } catch (error) {
            setButtonsLoad({ ...buttonsLoad, description: false });
            setErrors({ formError: error.message })
            console.log(error)
        }
    };

    const handleInputImagePic = (e) => {
        setErrors({})
        const [file] = e.target.files;
        const imgSize7Mb = 7 * 1024 * 1024;
        const isValidSize = file.size < imgSize7Mb;
        const isNameOfOneImageRegEx = /.(jpe?g|gif|png|webp)$/i;
        const isValidType = isNameOfOneImageRegEx.test(file.name);

        // console.log(imgSize7Mb)
        // console.log(file.size)

        if (!isValidSize) {
            e.target.value = "";
            return setErrors({ imageError: "La imagen es demasiado pesada" })
        }
        if (!isValidType) {
            e.target.value = "";
            return setErrors({ imageError: "La imagen solo puede tener formato .jpg, .png, .jpeg, .gif, .webp", })
        }

        const reader = new FileReader();
        reader.onloadstart = () => { setLoadImage(true) };
        reader.onloadend = () => {
            //console.log(reader.result)
            setSelectedImage(reader.result);

            setTimeout(() => {
                setLoadImage(false)
                let btnSubmitUpdate = document.getElementById("btnSubmitUpdateProfile");
                btnSubmitUpdate.click();
            }, 200)

        };
        reader.readAsDataURL(file);

        // handleFormChange()
    };

    const handleSubmitImage = async (e) => {
        e.preventDefault();

        setButtonsLoad({ ...buttonsLoad, image: true });

        // console.log("selected Image", selectedImage)

        if (selectedImage === "") {
            setButtonsLoad({ ...buttonsLoad, image: false });
            return setErrors({ imageError: "Debes seleccionar una imagen" })

        } else {
            const form = uploadForm.current;
            const fileInput = Array.from(form.elements).find(
                ({ name }) => name === "file"
            );

            const formData = new FormData();
            for (const file of fileInput.files) {
                formData.append("file", file);
            }

            try {
                const updateImageProfile = await AccountService.updateImageProfile(
                    token,
                    formData
                );
                // console.log(updateImageProfile);
                if (updateImageProfile.code === "UPDATEDPICPROFILE") {
                    // setNewAccount({ ...account, fullname: res.fullnameUpdated.fullname })
                    await getAccountData()
                    setButtonsLoad({ ...buttonsLoad, image: false });
                    toast.success(
                        "La foto de perfil se ha actualizado correctamente",
                        1500
                    );
                    if (selectedImage !== "https://res.cloudinary.com/dujeh3ms8/image/upload/v1684198553/userimage_tztuxu.png" && settingInfo.description !== null && settingInfo.description !== "" && selectedImage !== "") {

                        setActiveAccount(true)

                    }
                } else {

                    setButtonsLoad({ ...buttonsLoad, image: false });
                    toast.error("Hubo un error al actualizar la imagen", 1500);

                }
            } catch (error) {
                setErrors({ formError: error.message })
                setButtonsLoad({ ...buttonsLoad, image: false });
            }
        }
    };

    return (
        <>
            <Container
                component="main"
                maxWidth="xs"
                sx={{
                    zIndex: 100,
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >

                {
                    load ? (
                        <Typography>Cargando...</Typography>
                    )
                        :
                        (
                            <>
                                <Typography sx={{
                                    fontSize: 22,
                                    color: "#ADADAD",
                                    mt: 5,
                                    textAlign: "center"
                                }}>
                                    Solo un paso mas!
                                </Typography>
                                <Box sx={{
                                    width: "100%",
                                    bgcolor: "#ADADAD",
                                    height: "0.1vw",
                                    my: 2
                                }} />
                                <Typography sx={{
                                    fontSize: 16,
                                    color: "#ADADAD",
                                }}>
                                    Foto de perfil
                                </Typography>
                                <Box
                                    component={"form"}
                                    ref={uploadForm}
                                    name="changeImageProfile"
                                    onSubmit={(e) => handleSubmitImage(e)}
                                >
                                    <input
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg, image/gif, image/webp"
                                        name="file"
                                        id="selectImageProfile"
                                        style={{ display: "none" }}
                                        onChange={(e) => handleInputImagePic(e)}
                                    />
                                    <label htmlFor="selectImageProfile">
                                        <Box
                                            sx={{
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {

                                                buttonsLoad.image ? (
                                                    <Box
                                                        sx={{
                                                            width: 150,
                                                            height: 150,
                                                            mt: { xs: 0, md: 3 },
                                                            mb: { xs: 3, md: 3 },
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderRadius: "50%",
                                                            border: "solid 0.1vw #ADADAD",
                                                        }}
                                                    >
                                                        <CircularProgress sx={{ color: "#ADADAD" }} />
                                                    </Box>
                                                ) : (
                                                    <Avatar
                                                        sx={{
                                                            width: 150,
                                                            height: 150,
                                                            mt: { xs: 0, md: 3 },
                                                            mb: { xs: 3, md: 3 },
                                                            cursor: "pointer",
                                                        }}
                                                        src={selectedImage}
                                                    />
                                                )

                                            }
                                        </Box>
                                        <Box>
                                            {
                                                errors.imageError && <Alert severity="error">{errors.imageError}</Alert>
                                            }

                                            {
                                                loadImage && <Typography>Cargando Imagen</Typography>
                                            }

                                        </Box>
                                    </label>
                                    <button
                                        style={{ display: "none" }}
                                        type="submit"
                                        id="btnSubmitUpdateProfile"
                                    >
                                        submit
                                    </button>
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    bgcolor: "#ADADAD",
                                    height: "0.1vw",
                                    my: 2
                                }} />
                                <Box
                                    sx={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <TextareaAutosize
                                        value={settingInfo.description}
                                        onChange={(e) => handleChangeData(e)}
                                        type="text"

                                        style={{
                                            marginRight: 20,
                                            width: 250,
                                            bgcolor: "white",
                                            border: "0.1vw solid #ADADAD",
                                            borderRadius: 5,
                                            textAlign: "center",
                                            padding: 2,
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            '&::-webkit-scrollbar': { display: 'none' },
                                            minHeight: 80,

                                        }}
                                        // multiline
                                        placeholder="Descripción"
                                        maxRows={3}
                                        name="description"
                                    />
                                    <LoadingButton
                                        variant="contained"
                                        loading={buttonsLoad.description}
                                        onClick={() => handleSubmitDesc()}
                                    >
                                        Guardar
                                    </LoadingButton>
                                </Box>
                                <Box sx={{
                                    width: "100%",
                                    bgcolor: "#ADADAD",
                                    height: "0.1vw",
                                    my: 2
                                }} />

                                <Box>
                                    {
                                        errors.formError && <Alert severity="error">{errors.formError}</Alert>
                                    }
                                </Box>

                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        navigate("/")
                                    }}
                                    disabled={!activeAccount ? true : false}
                                    sx={{
                                        // position: "fixed",
                                        zIndex: 100,
                                        // left: "50%",
                                        // transform: "translateX(-50%)",
                                        mt: { xs: 5, md: 5 },
                                        mb: { xs: 0, md: 0, lg: 5 },
                                    }}
                                >
                                    {activeAccount ? "Terminar registro" : "Completa tu perfil para continuar"}
                                </Button>
                            </>
                        )
                }

            </Container>
            <Box
                component={"img"}
                src={Waves}
                sx={{ width: "100%", bottom: 0, position: "fixed" }}
            />
        </>
    );
};

export default RegisterStepThree;
