import * as React from "react";
import Avatar from "@mui/material/Avatar";
// import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LogoColor from "../assets/images/LogoColor.svg";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Waves from "../assets/images/waves.svg";
import LoadingButton from '@mui/lab/LoadingButton';
import AuthService from "../services/AuthService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {"Copyright © "}
            <Link color="inherit" href="https://hoshii.co/">
                hoshii
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const initialData = {
    password:"",
    confirmPassword:""
}

export default function RecoverPassword() {

    const [formData, setFormData] = React.useState(initialData)
    const [load, setLoad] = React.useState(false)
    const [errors, setErrors] = React.useState({})

    const params = useParams();

    const navigate = useNavigate()

    const handlerInputChange = (e) => {
        setErrors({});
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setFormData(formInfo => {
            return {
                ...formInfo,
                [name]: value
            }
        })
    }

    const  RecoverPassword = async (e) => {
        e.preventDefault()
        setLoad(true)

        try {
            setLoad(false)
            if(formData.password === "") return setErrors({ password: "Debes especificar una contraseña" })
            if(formData.confirmPassword === "") return setErrors({ confirmPassword: "Debes especificar una contraseña" })
            if (formData.password !== formData.confirmPassword) return setErrors({ formError: "Oops parece que las contraseñas no coinciden" })
            
            const info = { password: formData.password, token: params.id }
            await AuthService.restorePassword(info)
            toast.success("Tu contraseña se ha actualizado correctamente", { autoClose: 2000 })
            navigate("/login")

        } catch (error) {
            console.log(error)
            setErrors({ formError: error.messsage })
        }

    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 0,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar src={LogoColor} sx={{ width: 100, height: 100, m: 4 }} />
                    <Typography component="h1" variant="h5" textAlign={"center"}>
                        Ingresa tu nueva contraseña
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        sx={{ mt: 1 }}
                        onSubmit={(e) => { RecoverPassword(e) }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            type="password"
                            label="Contraseña nueva"
                            name="password"
                            autoComplete="password"
                            value={formData.password}
                            onChange={(e) => { handlerInputChange(e) }}
                            autoFocus
                            error={!!errors.password}
                            helperText={errors.password ? errors.password : ""}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirmPassword"
                            label="Confirmar nueva contraseña"
                            type="password"
                            id="confirmPassword"
                            autoComplete="current-password"
                            value={formData.confirmPassword}
                            onChange={(e) => { handlerInputChange(e) }}
                            error={!!errors.confirmPassword}
                            helperText={errors.confirmPassword ? errors.confirmPassword : ""}
                        />

                        {
                            errors.formError ? (<Alert severity="error">{errors.formError}</Alert>)
                                :
                                (
                                    <LoadingButton loading={load} type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}>
                                        Actualizar contraseña
                                    </LoadingButton>
                                )
                        }

                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
            <Box
                component={"img"}
                src={Waves}
                sx={{ width: "100%", bottom: 0, position: "absolute" }}
            />
        </>
    );
}
