import React from 'react'
import { Avatar, Box, Typography } from '@mui/material'


const ChatContent = ({ data, account, selectionSelectedChat }) => {
    const [chatAccountData, setChatAccountData] = React.useState(null)

    //Functions
    const extractInfoData = React.useCallback(() => {
        // console.log("AccountsInfo", data)
        // console.log(account)
        if (account !== null) {
            let getAccountdata = data.accounts.filter((acc) => acc._id !== account._id)
            // console.log(getAccountdata)
            setChatAccountData(getAccountdata[0])
        }
    }, [account,data.accounts])

    const viewChathandler = () => {
        // setSelectedChat(data._id)
        selectionSelectedChat(data)
      }

    React.useEffect(() => {
        extractInfoData()
    }, [extractInfoData])

    return (
        <Box
            onClick={() => viewChathandler()}
            sx={{
                display: 'flex',
                pl: 2,
                py: 1,
                transition: 'ease-in-out 200ms',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: '#DEDEDE'
                },
            }}
        >
            <Box>
                <Avatar
                    alt={chatAccountData?.fullname}
                    src={chatAccountData?.pic_profile}
                    sx={{
                        width: 45,
                        height: 45
                    }}
                />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: 2
                }}
            >
                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{chatAccountData && chatAccountData.fullname}</Typography>
                <Typography sx={{ fontSize: 12 }}>{data.latestMessage ? data.latestMessage.message : "Nuevo chat"}</Typography>
            </Box>
        </Box>
    )
}

export default ChatContent