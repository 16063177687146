import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Alert, Box, Button, Typography } from "@mui/material";
// import { useMotionValue } from "framer-motion";
import LikeBackground from "../assets/images/LikeBackground.png";
import DislikeBackground from "../assets/images/DislikeBackground.png";
import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";

//navigation
import { useNavigate } from "react-router-dom";

//Services
import HomeService from "../services/HomeService";

//Context
import useAuth from "../context/auth/useAuth";

//Custom Components
import ChoiceBoxProfile from "../components/home/ChoiceBoxProfile";
import ChoiceBoxCard from "../components/home/ChoiceBoxCard";
import ChoiceBoxMaster from "../components/home/ChoiceBoxMaster";
// import ChoiceBoxAd from "../components/home/ChoiceBoxAd";
import ChoiceSwipeProfile from "../components/home/ChoiceSwipeProfile";
import ChoiceSwipeCard from "../components/home/ChoiceSwipeCard";
import ChoiceSwipeMaster from "../components/home/ChoiceSwipeMaster";
import HomeLoader from "../components/home/HomeLoader";
import ErrorNotification from "../components/notifications/ErrorNotification";
import AccountService from "../services/AccountService";

//social media icons
import facebook from "../assets/socialMedia/facebook.png";
import instagram from "../assets/socialMedia/instagram.png";
import snapchat from "../assets/socialMedia/snapchat.png";
import linkedin from "../assets/socialMedia/linkedin.png";
import tiktok from "../assets/socialMedia/tiktok.png";
import twitter from "../assets/socialMedia/twitter.png";
import onlyfans from "../assets/socialMedia/onlyfans.png";

const socialMediaArrayImages = [
  {
    name: "facebook",
    image: facebook,
  },
  {
    name: "instagram",
    image: instagram,
  },
  {
    name: "twitter",
    image: twitter,
  },
  {
    name: "tiktok",
    image: tiktok,
  },
  {
    name: "linkedin",
    image: linkedin,
  },
  {
    name: "snapchat",
    image: snapchat,
  },
  {
    name: "onlyfans",
    image: onlyfans,
  },
];

const Home = () => {
  const { user, saveAccount } = useAuth();
  const token = !user?.accessToken ? null : user.accessToken;
  const [cardList, setCardList] = useState([]);
  const [selectedcard, setSelectedcard] = React.useState(null);
  const [warning, setWarning] = useState("");
  const [mainContentDone, setMainContentDone] = useState(false);
  const [loading, setloading] = useState(false);
  const [openErrorNotificationModal, setOpenErrorNotificationModal] =
    React.useState(false);
  const [cardRotation, setCardRotation] = useState("");
  const [choiceDirection, setChoiceDirection] = useState("");
  // const [open, setOpen] = useState(false);

  const [socialMediaArray, setSocialMediaArray] = useState([]);

  const [fullTags, setFullTags] = useState(false);

  const navigate = useNavigate();

  // console.log(cardList)

  const removeCardList = (selectionCard) => {
    if (cardList.length > 0) {
      const newCardsList = cardList.filter(
        (card) => card._id !== selectionCard._id
      );
      setCardList(newCardsList);
      setSelectedcard(newCardsList[0]);
    }
  };

  const handlerAccountLike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        userMatch: selectedcard._id,
      };

      try {
        const likeAccount = await HomeService.likeAccount(formData, token);
        // console.log(likeAccount);
        if (likeAccount.code !== "200") {
          // console.log(likeAccount.code, likeAccount.msg);
          setWarning(likeAccount.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          //Function para mostrara la siguiente card
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setWarning("Hubo un errorr con el like, intenta de nuevo.");
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlersAccountDislike = async () => {
    setMainContentDone(true);
    setloading(true);

    // console.log("disliked");

    if (selectedcard !== null) {
      let formData = {
        userDislike: selectedcard._id,
      };
      try {
        const dislikeAccount = await HomeService.dislikeAccount(
          formData,
          token
        );
        // console.log(dislikeAccount);
        if (dislikeAccount.code !== "200") {
          // console.log(dislikeAccount.code, dislikeAccount.msg);
          setWarning(dislikeAccount.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Aca va la función para quitar la card de la lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlerCardLike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        userLike: selectedcard.accountId ? selectedcard.accountId._id : null,
        cardId: selectedcard._id,
      };

      try {
        const likeCard = await HomeService.likeCard(formData, token);
        if (likeCard.code !== "200") {
          // console.log(likeCard.code, likeCard.msg);
          setWarning(likeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para eliminar una card de la lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlerCardDislike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        userdislike: selectedcard.accountId ? selectedcard.accountId._id : null,
        cardId: selectedcard._id,
      };

      try {
        const dislikeCard = await HomeService.dislikeCard(formData, token);
        if (dislikeCard.code !== "200") {
          // console.log(dislikeCard.code, dislikeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para quitar la card de lla lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlerMasterDislike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        cardId: selectedcard._id,
      };

      try {
        const dislikeCard = await HomeService.dislikeMaster(formData, token);
        if (dislikeCard.code !== "200") {
          // console.log(dislikeCard.code, dislikeCard.msg);
          setWarning(dislikeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para quitar la card de lla lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlerNeutralMaster = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        cardId: selectedcard._id,
      };

      try {
        const likeCard = await HomeService.NeutralSwipe(formData, token);
        if (likeCard.code !== "200") {
          // console.log(likeCard.code, likeCard.msg);
          setWarning(likeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para quitar la card de lla lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const fetchAllCards = React.useCallback(async () => {
    setMainContentDone(true);
    setloading(true);

    try {
      let getCards = await HomeService.getCards(token);
      // console.log(getCards);
      if (getCards.cards.length === 0) {
        setMainContentDone(false);
        setloading(false);
        setWarning("No hay mas cards para mostrar");
      } else {
        setCardList(getCards.cards);
        setMainContentDone(false);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setWarning("Hubo un error al traer las cards");
    }
  }, [token]);

  const setSelectionCard = React.useCallback(() => {
    setFullTags(false);
    if (!selectedcard && cardList.length !== 0) {
      setSelectedcard(cardList[0]);
    }
  }, [cardList, selectedcard]);

  const getAccountData = React.useCallback(async () => {
    try {
      const accountData = await AccountService.getAccount(token);
      if (accountData.code === "EXISTACCOUNT") {
        saveAccount(accountData.account);
      } else {
        setWarning(accountData.msg);
        setOpenErrorNotificationModal(true);
      }
    } catch (error) {
      console.log(error);
      setWarning("Failed to load server!, please try later");
    }
  }, [token]);

  useEffect(() => {
    if (cardList.length === 0) {
      void fetchAllCards();
    } else {
      setSelectionCard();
    }
  }, [fetchAllCards, cardList.length, setSelectionCard]);

  useEffect(() => {
    void getAccountData();
  }, [getAccountData]);

  useEffect(() => {
    if (selectedcard) {
      if (selectedcard.fullname !== undefined) {
        const availableSocialMedia = selectedcard.socialMedia.filter(
          (item) => item.url !== ""
        );

        const baseArray = [];

        availableSocialMedia.forEach((item) => {
          socialMediaArrayImages.forEach((socialImage) => {
            console.log(socialImage);
            if (socialImage.name === item.name) {
              baseArray.push({
                name: item.name,
                url: item.url,
                image: socialImage.image,
              });
            }
          });
        });

        console.log("REDES SOCIALES");

        console.log(baseArray);

        setSocialMediaArray(baseArray);
      }
    }
  }, [selectedcard]);

  return (
    <>
      <Header />
      <Box
        sx={{
          width: { xs: "20vw", md: 500 },
          height: { xs: "20vw", md: 500 },
          position: "fixed",
          right: 0,
          top: { xs: 0, md: 0 },
          transition: "0.3s ease",
          opacity: choiceDirection === "right" ? 1 : 0,
          zIndex: 100,
        }}
      >
        <Box
          component={"img"}
          src={LikeBackground}
          sx={{
            width: { xs: "20vw", md: 500 },
            height: { xs: "20vw", md: 500 },
          }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "20vw", md: 500 },
          height: { xs: "20vw", md: 500 },
          position: "fixed",
          left: 0,
          top: { xs: 0, md: 0 },
          transition: "0.3s ease",
          opacity: choiceDirection === "left" ? 1 : 0,
          zIndex: 100,
        }}
      >
        <Box
          component={"img"}
          src={DislikeBackground}
          sx={{
            width: { xs: "20vw", md: 500 },
            height: { xs: "20vw", md: 500 },
          }}
        />
      </Box>
      <Box
        component={"main"}
        sx={{
          mt: { xs: 7, md: 7 },
          mb: { xs: 15, md: 15 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          flexDirection: "column",
          zIndex: 99,
          overflowX: "hidden",
        }}
      >
        {mainContentDone && loading && <HomeLoader />}
        {!mainContentDone && !loading && warning !== "" && (
          <Box
            sx={{
              width: "100%",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <Alert severity="info" sx={{width: "70%"}}>Lo sentimos en el momento no hay mas contenido disponible, por favor intentalo mas tarde.</Alert> */}
            <Alert severity="info" sx={{ width: "70%" }}>
              {warning}
            </Alert>
          </Box>
        )}
        {!mainContentDone && selectedcard && (
          <>
            <Box>
              {selectedcard.fullname !== undefined ? (
                <ChoiceSwipeProfile
                  handlerAccountLike={handlerAccountLike}
                  handlersAccountDislike={handlersAccountDislike}
                  data={selectedcard}
                  cardRotation={cardRotation}
                  setCardRotation={setCardRotation}
                  setChoiceDirection={setChoiceDirection}
                />
              ) : (
                <>
                  {selectedcard.type === "Profile" && (
                    <ChoiceSwipeCard
                      // setOpen={setOpen}
                      data={selectedcard}
                      handlerCardLike={handlerCardLike}
                      handlerCardDislike={handlerCardDislike}
                      cardRotation={cardRotation}
                      setCardRotation={setCardRotation}
                      setChoiceDirection={setChoiceDirection}
                    />
                  )}
                  {selectedcard.type === "Master" && (
                    <ChoiceSwipeMaster
                      // setOpen={setOpen}
                      data={selectedcard}
                      cardRotation={cardRotation}
                      handlerCardLike={handlerCardLike}
                      handlerMasterDislike={handlerMasterDislike}
                      handlerNeutralMaster={handlerNeutralMaster}
                      setCardRotation={setCardRotation}
                      setChoiceDirection={setChoiceDirection}
                    />
                  )}
                </>
              )}
            </Box>
          </>
        )}
        {!mainContentDone && !loading && (
          <>
            <Box
              sx={{
                width: { xs: "98vw", md: 400 },
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                mt: -3,
              }}
            >
              {selectedcard?.fullname === undefined ? (
                selectedcard?.cardtags.length > 5 && !fullTags ? (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {
                        navigate("/search/" + selectedcard.cardtags[0]);
                      }}
                    >
                      {selectedcard.cardtags[0]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {
                        navigate("/search/" + selectedcard.cardtags[1]);
                      }}
                    >
                      {selectedcard.cardtags[1]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {
                        navigate("/search/" + selectedcard.cardtags[2]);
                      }}
                    >
                      {selectedcard.cardtags[2]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {
                        navigate("/search/" + selectedcard.cardtags[3]);
                      }}
                    >
                      {selectedcard.cardtags[3]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {
                        navigate("/search/" + selectedcard.cardtags[4]);
                      }}
                    >
                      {selectedcard.cardtags[4]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {
                        setFullTags(true);
                      }}
                    >
                      {` + ${selectedcard.cardtags.length - 5}`}
                    </Button>
                  </>
                ) : (
                  selectedcard?.cardtags.map((tag, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {
                        navigate("/search/" + tag);
                      }}
                    >
                      {tag}
                    </Button>
                  ))
                )
              ) : selectedcard?.usertags.length > 5 && !fullTags ? (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + selectedcard.usertags[0]);
                    }}
                  >
                    {selectedcard.usertags[0]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + selectedcard.usertags[1]);
                    }}
                  >
                    {selectedcard.usertags[1]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + selectedcard.usertags[2]);
                    }}
                  >
                    {selectedcard.usertags[2]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + selectedcard.usertags[3]);
                    }}
                  >
                    {selectedcard.usertags[3]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + selectedcard.usertags[4]);
                    }}
                  >
                    {selectedcard.usertags[4]}
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      setFullTags(true);
                    }}
                  >
                    {` + ${selectedcard.usertags.length - 5}`}
                  </Button>
                </>
              ) : (
                selectedcard?.usertags.map((tag, index) => (
                  <Button
                    key={index}
                    variant="contained"
                    sx={{
                      margin: 1,
                    }}
                    onClick={() => {
                      navigate("/search/" + tag);
                    }}
                  >
                    {tag}
                  </Button>
                ))
              )}
            </Box>
            <Box
              sx={{
                width: { xs: "95vw", md: 400 },
                py: 2,
                borderTop: "0.1vw solid #ADADAD",
                borderBottom: "0.1vw solid #ADADAD",
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "95%",
                  overflowX: "hidden",
                }}
              >
                {selectedcard?.description}
              </Typography>
            </Box>
            {selectedcard && (
              <Box
                sx={{
                  width: { xs: "95vw", md: 400 },
                  py: 2,
                  borderTop: "0.1vw solid #ADADAD",
                  borderBottom: "0.1vw solid #ADADAD",
                  mt: 2,
                  display: socialMediaArray.length > 0 ? "flex" : "none",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  sx={{
                    width: "100%",
                    overflowX: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  redes sociales
                </Typography>
                {selectedcard.fullname !== undefined && (
                  <>
                    <Box
                      sx={{
                        width: { xs: "95vw", md: 400 },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexWrap: "wrap",
                        // borderTop: "0.1vw solid #ADADAD",
                        // borderBottom: "0.1vw solid #ADADAD",
                      }}
                    >
                      {socialMediaArray.map((socialMedia) => (
                        <a href={"https://www." + socialMedia.url}>
                          <Box
                            key={socialMedia.name}
                            sx={{
                              width: 37,
                              height: 37,
                              backgroundImage: "url(" + socialMedia.image + ")",
                              backgroundPosition: "center",
                              backgroundSize: "cover",
                              margin: 1,
                              cursor: "pointer",
                            }}
                          />
                        </a>
                      ))}
                    </Box>
                  </>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
      {!mainContentDone && selectedcard && (
        <Box>
          {selectedcard.fullname !== undefined ? (
            <ChoiceBoxProfile
              handlerAccountLike={handlerAccountLike}
              handlersAccountDislike={handlersAccountDislike}
            />
          ) : (
            <>
              {selectedcard.type === "Profile" && (
                <ChoiceBoxCard
                  handlerCardLike={handlerCardLike}
                  handlerCardDislike={handlerCardDislike}
                  handlerNeutralMaster={handlerNeutralMaster}
                />
              )}
              {selectedcard.type === "Master" && (
                <ChoiceBoxMaster
                  handlerCardLike={handlerCardLike}
                  handlerMasterDislike={handlerMasterDislike}
                  handlerNeutralMaster={handlerNeutralMaster}
                />
              )}
            </>
          )}
        </Box>
      )}

      <Box
        component={"img"}
        src={Wave1}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 100,
        }}
      />
      <Box
        component={"img"}
        src={Wave2}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 80,
        }}
      />
      <ErrorNotification
        openErrorNotificationModal={openErrorNotificationModal}
        setOpenErrorNotificationModal={setOpenErrorNotificationModal}
      />
    </>
  );
};

export default Home;
