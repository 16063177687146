import ConfigServer from "../settings/config"
const CREATE_MEDIA_FILES_API_URL =  `${ConfigServer.urlServer}/api/media/v1/create`

const MediaService = {}

MediaService.createMedia = async (formData, token) => {
    let createdCard = await fetch(CREATE_MEDIA_FILES_API_URL,{
        method:'POST',
        headers:{
            "Authorization": `Bearer ${token}`
        },
        body: formData
    })
    if(!createdCard.ok){
        let response = {
            code: '500',
            msg: "Something went wrong"
        }
        return response
    }

    let data = await createdCard.json()
    return data
}

export default MediaService