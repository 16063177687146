import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Alert, Box, Button, Typography } from "@mui/material";
// import { useMotionValue } from "framer-motion";
import LikeBackground from "../assets/images/LikeBackground.png";
import DislikeBackground from "../assets/images/DislikeBackground.png";
import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";

//Services
import HomeService from "../services/HomeService";

//navigation
import { useNavigate } from "react-router-dom";

//Context
import useAuth from "../context/auth/useAuth";

//Custom Components
// import ChoiceBoxProfile from "../components/home/ChoiceBoxProfile";
import ChoiceBoxCard from "../components/home/ChoiceBoxCard";
// import ChoiceBoxMaster from "../components/home/ChoiceBoxMaster";
// import ChoiceBoxAd from "../components/home/ChoiceBoxAd";
// import ChoiceSwipeProfile from "../components/home/ChoiceSwipeProfile";
import ChoiceSwipeCard from "../components/home/ChoiceSwipeCard";
// import ChoiceSwipeMaster from "../components/home/ChoiceSwipeMaster";
import HomeLoader from "../components/home/HomeLoader";
import ErrorNotification from "../components/notifications/ErrorNotification";
import { useParams } from "react-router-dom";
import TrendingService from "../services/TrendingService";

const TagView = () => {
  const { user } = useAuth();
  const token = !user?.accessToken ? null : user.accessToken;
  const [cardList, setCardList] = useState([]);
  const [selectedcard, setSelectedcard] = React.useState(null);
  const [warning, setWarning] = useState("");
  const [mainContentDone, setMainContentDone] = useState(false);
  const [loading, setloading] = useState(false);
  const [openErrorNotificationModal, setOpenErrorNotificationModal] = React.useState(false);
  const [cardRotation, setCardRotation] = useState("");
  const [choiceDirection, setChoiceDirection] = useState("");
  // const [paramCapture, setParamCapture] = React.useState(null)
  // const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const [fullTags, setFullTags] = useState(false)

  const params = useParams();

  // console.log(params.tag)

  const removeCardList = (selectionCard) => {
    if (cardList.length > 0) {
      const newCardsList = cardList.filter(
        (card) => card._id !== selectionCard._id
      );
      setCardList(newCardsList);
      setSelectedcard(newCardsList[0]);
    }
  };

  const handlerCardLike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        userLike: selectedcard.accountId ? selectedcard.accountId._id : null,
        cardId: selectedcard._id,
      };

      try {
        const likeCard = await HomeService.likeCard(formData, token);
        if (likeCard.code !== "200") {
          // console.log(likeCard.code, likeCard.msg);
          setWarning(likeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para eliminar una card de la lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlerCardDislike = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        userdislike: selectedcard.accountId ? selectedcard.accountId._id : null,
        cardId: selectedcard._id,
      };

      try {
        const dislikeCard = await HomeService.dislikeCard(formData, token);
        if (dislikeCard.code !== "200") {
          // console.log(dislikeCard.code, dislikeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para quitar la card de lla lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const handlerNeutralMaster = async () => {
    setMainContentDone(true);
    setloading(true);

    if (selectedcard !== null) {
      let formData = {
        cardId: selectedcard._id,
      };

      try {
        const likeCard = await HomeService.NeutralSwipe(formData, token);
        if (likeCard.code !== "200") {
          // console.log(likeCard.code, likeCard.msg);
          setWarning(likeCard.msg);
          setMainContentDone(false);
          setloading(false);
        } else {
          // Funcion para quitar la card de lla lista
          removeCardList(selectedcard);
          setMainContentDone(false);
          setloading(false);
        }
      } catch (error) {
        console.log(error);
        setMainContentDone(false);
        setloading(false);
      }
    } else {
      setMainContentDone(false);
      setloading(false);
    }
  };

  const fetchAllCards = React.useCallback(async (tag = null) => {
    setMainContentDone(true);
    setloading(true);
    let paramTag = tag === null ? params.tag : tag
    try {
      let getCards = await TrendingService.getCards(token, paramTag);
      // console.log(getCards);
      if (getCards.cards.length === 0) {
        setMainContentDone(false);
        setloading(false);
        setWarning("No hay mas cards para mostrar");
      } else {
        setCardList(getCards.cards);
        setMainContentDone(false);
        setloading(false);
      }
    } catch (error) {
      console.log(error);
      setWarning("Hubo un error al traer las cards");
    }

  }, [token, params.tag]);

  const setSelectionCard = React.useCallback(() => {
    setFullTags(false);
    if (!selectedcard && cardList.length !== 0) {
      setSelectedcard(cardList[0]);
    }
  }, [cardList, selectedcard]);

  useEffect(() => {
      if (cardList.length === 0) {
        void fetchAllCards();
      } else {
        setSelectionCard();
      }
  }, [fetchAllCards, cardList.length, setSelectionCard, params.tag]);

  const updateTag = (tag) => {
    setSelectedcard(null)
    fetchAllCards(tag)
    navigate("/search/" + tag)
  }

  return (
    <>
      <Header />
      <Box
        sx={{
          width: { xs: "20vw", md: 500 },
          height: { xs: "20vw", md: 500 },
          position: "fixed",
          right: 0,
          top: { xs: 0, md: 0 },
          transition: "0.3s ease",
          opacity: choiceDirection === "right" ? 1 : 0,
          zIndex: 100,
        }}
      >
        <Box
          component={"img"}
          src={LikeBackground}
          sx={{
            width: { xs: "20vw", md: 500 },
            height: { xs: "20vw", md: 500 },
          }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "20vw", md: 500 },
          height: { xs: "20vw", md: 500 },
          position: "fixed",
          left: 0,
          top: { xs: 0, md: 0 },
          transition: "0.3s ease",
          opacity: choiceDirection === "left" ? 1 : 0,
          zIndex: 100,
        }}
      >
        <Box
          component={"img"}
          src={DislikeBackground}
          sx={{
            width: { xs: "20vw", md: 500 },
            height: { xs: "20vw", md: 500 },
          }}
        />
      </Box>
      <Box
        component={"main"}
        sx={{
          mt: { xs: 7, md: 7 },
          mb: { xs: 15, md: 15 },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          flexDirection: "column",
          zIndex: 99,
          overflowX: "hidden",
        }}
      >
        {mainContentDone && loading && <HomeLoader />}
        {!mainContentDone && !loading && warning !== "" && (
          <Box
            sx={{
              width: "100%",
              height: "90vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {/* <Alert severity="info" sx={{width: "70%"}}>Lo sentimos en el momento no hay mas contenido disponible, por favor intentalo mas tarde.</Alert> */}
            <Alert severity="info" sx={{ width: "70%" }}>
              {warning}
            </Alert>
          </Box>
        )}
        {!mainContentDone && selectedcard && (
          <>
            <Box>
              <ChoiceSwipeCard
                // setOpen={setOpen}
                data={selectedcard}
                handlerCardLike={handlerCardLike}
                handlerCardDislike={handlerCardDislike}
                cardRotation={cardRotation}
                setCardRotation={setCardRotation}
                setChoiceDirection={setChoiceDirection}
              />
            </Box>
          </>
        )}
        {!mainContentDone && !loading && (
          <>
            <Box
              sx={{
                width: { xs: "98vw", md: 400 },
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                mt: -3,
              }}
            >
              {
                selectedcard?.cardtags.length > 5 && !fullTags ? (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => updateTag(selectedcard.cardtags[0]) }
                    >
                      {selectedcard.cardtags[0]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => updateTag(selectedcard.cardtags[1])}
                    >
                      {selectedcard.cardtags[1]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => updateTag(selectedcard.cardtags[2]) }
                    >
                      {selectedcard.cardtags[2]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => updateTag(selectedcard.cardtags[3]) }
                    >
                      {selectedcard.cardtags[3]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => updateTag(selectedcard.cardtags[4]) }
                    >
                      {selectedcard.cardtags[4]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => {setFullTags(true)}}
                    >
                      {` + ${selectedcard.cardtags.length - 5}`}
                    </Button>
                  </>
                ) : (
                  selectedcard?.cardtags.map((tag, index) => (
                    <Button
                      key={index}
                      variant="contained"
                      sx={{
                        margin: 1,
                      }}
                      onClick={() => updateTag(tag) }
                    >
                      {tag}
                    </Button>
                  ))
                )
              }
            </Box>
            <Box
              sx={{
                width: { xs: "95vw", md: 400 },
                py: 2,
                borderTop: "0.1vw solid #ADADAD",
                borderBottom: "0.1vw solid #ADADAD",
                mt: 2,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  width: "95%",
                  overflowX:'hidden'
                }}
              >
                {selectedcard?.description}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      {!mainContentDone && selectedcard && (
        <Box>
          <ChoiceBoxCard
            handlerCardLike={handlerCardLike}
            handlerCardDislike={handlerCardDislike}
            handlerNeutralMaster={handlerNeutralMaster}
          />
        </Box>
      )}

      <Box
        component={"img"}
        src={Wave1}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 100,
        }}
      />
      <Box
        component={"img"}
        src={Wave2}
        sx={{
          width: "100%",
          height: "auto",
          bottom: -1,
          position: "fixed",
          zIndex: 80,
        }}
      />
      <ErrorNotification
        openErrorNotificationModal={openErrorNotificationModal}
        setOpenErrorNotificationModal={setOpenErrorNotificationModal}
      />
    </>
  );
};

export default TagView;
