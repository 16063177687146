import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import React, { useRef } from "react";
// import TestImage from "../assets/images/testImage.jpg";
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";
import { toast } from "react-toastify";
import TagSlider from "../components/TagSlider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MediaService from "../services/MediaService";
import AccountService from "../services/AccountService";
import useAuth from "../context/auth/useAuth";

import Wave1 from "../assets/images/Wave1.svg";
import Wave2 from "../assets/images/Wave2.svg";

//navigation
import { useNavigate } from "react-router-dom";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

//QA
// const uploadPreset = "hoshii-master-card"

// PRD
const uploadPreset = "hoshii-files-cards"


const CreateCard: React.FC = () => {
    const cropperRef = useRef<ReactCropperElement>(null);

    const { user } = useAuth();
    const token = user ? user.accessToken : null;

    //   const [previewImage, setPreviewImage] = React.useState("");

    const ABCDta = /^[a-zA-Z0-9]{1}[a-zA-Z0-9 ]{1,28}[a-zA-Z0-9]{1}$/g;

    const [loading, setLoading] = React.useState(false);

    const [selectedImage, setSelectedImage] = React.useState("");

    const [editMode, setEditMode] = React.useState(false);

    const [currentInputTagText, setCurrentInputTagText] = React.useState("");

    const [errors, setErrors] = React.useState({});

    const [contentData, setContentData] = React.useState({
        title: "",
        cardtags: [],
        type: "Profile",
        description: "",
        files: [],
    });

    const navigate = useNavigate();

    const uploadForm = React.useRef(null);

    const createCard = async () => {
        setLoading(true);
        const newErrors = {};

        if (contentData.files.length === 0)
            newErrors.image = "Debes seleccionar al menos una imagen";
        if (contentData.title === "") newErrors.title = "El título es requerido";
        if (contentData.title.length < 5)
            newErrors.title = "El título es demasiado corto";
        // if (contentData.title.length > 80) newErrors.title = "El título es demasiado largo máx. 80 caracteres"
        if (contentData.description === "")
            newErrors.description = "La descripción es requerida";
        if (contentData.description.length < 10)
            newErrors.description =
                "La descripción debe contener al menos 30 caracteres";
        if (contentData.cardtags.length < 1)
            newErrors.cardtags = "Debes indicar al menos 1 tags";

        // const form = uploadForm.current;
        const imagesFilesArray = contentData.files.map((file, index) => {
            // return b64toBlob(file, file.split(";")[0].split(":")[1].trim())
            let tipo = file.match(/data:image\/([a-zA-Z]+);base64,/)[1];
            // Eliminamos el prefijo data:image/...;base64, de la cadena
            let datos = file.replace(/data:image\/([a-zA-Z]+);base64,/, "");
            // Decodificamos la cadena base64 a un array de bytes
            let bytes = atob(datos);
            // Creamos un array vacío para almacenar los bytes
            let array = [];
            // Recorremos los bytes y los añadimos al array
            for (let i = 0; i < bytes.length; i++) {
                array.push(bytes.charCodeAt(i));
            }
            // Creamos un objeto Blob con el array y el tipo de imagen
            let blob = new Blob([new Uint8Array(array)], { type: "image/" + tipo });
            // Creamos un objeto File a partir del Blob
            let imageFile = new File([blob], `image${index}.` + tipo, {
                type: "image/" + tipo,
            });

            return imageFile;
        });

        const formImages = new FormData();
        formImages.append("upload_preset", uploadPreset);
        formImages.append("typeImage", "ProfileImage");
        for (const file of imagesFilesArray) {
            formImages.append("files", file);
        }

        if (Object.keys(newErrors).length === 0) {
            //submitting
            try {
                // await AdminService.createAd(formData, token)
                const createMedia = await MediaService.createMedia(formImages, token);
                // console.log(createMedia);
                // setLoading(false)

                const formData = new FormData();

                formData.append("title", contentData.title);
                formData.append("description", contentData.description);
                formData.append("type", contentData.type);
                formData.append("cardtags", JSON.stringify(contentData.cardtags));
                formData.append("files", JSON.stringify(createMedia));

                const createCard = await AccountService.createCard(formData, token);
                // console.log(createCard)
                if (createCard.code === "500") {
                    setLoading(false);
                    newErrors.formError = "Hubo un error al crear la publicación";
                    setErrors(newErrors);
                    setTimeout(() => {
                        setErrors({});
                    }, 7000);
                } else {
                    setLoading(false);
                    navigate("/profile");
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
                newErrors.formError = "Hubo un error al crear la publicación";
                setErrors(newErrors);
                setTimeout(() => {
                    setErrors({});
                }, 7000);
            }
        } else {
            setLoading(false);
            setErrors(newErrors);
            setTimeout(() => {
                setErrors({});
            }, 7000);
        }
    };

    const addTagList = (tag) => {
        let oldTagList = contentData.cardtags;
        let findTag = oldTagList.find((t) => t.toLowerCase() === tag.toLowerCase());
        let isValidText = tag.match(ABCDta);
        setCurrentInputTagText("");
        if (findTag) return;
        if (
            tag === "ad" ||
            tag === "master" ||
            tag === "maestro" ||
            tag === "anuncio"
        )
            return;
        if (tag.length > 30)
            return toast.error("La tag solo puede contener 30 caracteres", {
                autoClose: 2000,
            });
        if (!isValidText)
            return toast.error("La tag solo puede contener letras y números", {
                autoClose: 2000,
            });
        // let ValidationTag = RemoveTilde(tag)
        let newTagList = [...oldTagList, tag.toLowerCase()];
        setContentData({ ...contentData, cardtags: newTagList });
    };

    const deleteTag = (tag) => {
        let oldTagList = contentData.cardtags;
        const tagListNoNTag = oldTagList.filter((t) => t !== tag);
        setContentData({ ...contentData, cardtags: tagListNoNTag });
    };

    const handleImageRemove = (index) => {
        setSelectedImage("");
        // console.log(selectedImage);
        const updatedImages = [...contentData.files];
        updatedImages.splice(index, 1);
        setContentData((card) => {
            return {
                ...card,
                files: [...updatedImages],
            };
        });
    };

    const handleInputImagePic = (e) => {
        e.preventDefault();

        const files = e.target.files;
        const fileList = Array.from(files);

        // console.log(fileList)

        // let imageArray = contentData.files

        const fileQuantity = fileList.length + contentData.files.length

        if (fileQuantity > 7) {
            return toast.error("Sólo puedes cargar máximo 7 imagenes", {
                autoClose: 2000,
            })
        };

        for (const imgElment of fileList) {
            const imgSize7Mb = 7 * 1024 * 1024;
            const isValidSize = imgElment.size < imgSize7Mb;
            const isNameOfOneImageRegEx = /.(jpe?g|gif|png|webp)$/i;
            const isValidType = isNameOfOneImageRegEx.test(imgElment.name);

            if (!isValidSize) {
                e.target.value = "";
                return toast.error("La imagen es demasido pesada, máximo 7mb", {
                    autoClose: 2000,
                });
            }
            if (!isValidType) {
                e.target.value = "";
                return toast.error(
                    "La imagen solo puede tener formato .jpg, .png, .jpeg, .gif, .webp",
                    { autoClose: 2000 }
                );
            }

            const reader = new FileReader();
            reader.onloadstart = () => {
                // setLoad(true)
            };
            reader.onloadend = () => {
                setContentData((contentData) => {
                    return {
                        ...contentData,
                        files: [...contentData.files, reader.result],
                    };
                });
            };
            reader.readAsDataURL(imgElment);
        }
    };

    const addToImage = () => {
        const inputChangeImage = document.getElementById("addImageToCard");
        if (inputChangeImage !== null) {
            inputChangeImage.click();
        }
    };

    return (
        <>
            {!loading ? (
                <Box
                    component={"main"}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        position: "relative",
                        zIndex: 101,
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            mt: 3,
                        }}
                    >
                        <Box
                            sx={{
                                width: { xs: "100%", md: "50%" },
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box component={"form"} ref={uploadForm}>
                                <Box
                                    hidden
                                    component={"input"}
                                    type="file"
                                    id="addImageToCard"
                                    multiple
                                    accept="image/*"
                                    name="files"
                                    onChange={(e) => handleInputImagePic(e)}
                                />
                            </Box>
                            {selectedImage === "" && contentData.files.length === 0 && (
                                <Box
                                    onClick={() => {
                                        addToImage();
                                    }}
                                    sx={{
                                        width: { xs: "80vw", md: "30vw" },
                                        height: { xs: "120vw", md: "45vw" },
                                        border: "0.1vw solid #ADADAD",
                                        borderRadius: 5,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        cursor: "pointer",
                                        bgcolor: "white",
                                    }}
                                >
                                    <AddCircleOutlineIcon
                                        sx={{
                                            width: { xs: "20vw", md: "10vw" },
                                            height: { xs: "20vw", md: "10vw" },
                                            color: "#ADADAD",
                                            mb: 2,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#ADADAD",
                                        }}
                                    >
                                        Seleccione una imagen para subir
                                    </Typography>
                                </Box>
                            )}
                            {selectedImage === "" && contentData.files.length > 0 && (
                                <Box
                                    onClick={() => {
                                        addToImage();
                                    }}
                                    sx={{
                                        width: { xs: "80vw", md: "30vw" },
                                        height: { xs: "120vw", md: "45vw" },
                                        border: "0.1vw solid #ADADAD",
                                        borderRadius: 5,
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        cursor: "pointer",
                                        bgcolor: "white",
                                    }}
                                >
                                    <AddCircleOutlineIcon
                                        sx={{
                                            width: { xs: "20vw", md: "10vw" },
                                            height: { xs: "20vw", md: "10vw" },
                                            color: "#ADADAD",
                                            mb: 2,
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            color: "#ADADAD",
                                        }}
                                    >
                                        Seleccione una imagen para subir
                                    </Typography>
                                </Box>
                            )}
                            {selectedImage !== "" && contentData.files.length > 0 && (
                                <>
                                    <Box
                                        sx={{
                                            width: { xs: "80vw", md: "30vw" },
                                            height: { xs: "120vw", md: "45vw" },
                                            border: "0.1vw solid #ADADAD",
                                            borderRadius: 5,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            overflow: "hidden",
                                            bgcolor: "white",
                                        }}
                                    >
                                        {editMode ? (
                                            <Cropper
                                                src={selectedImage}
                                                style={{
                                                    height: "100%",
                                                    width: "100%",
                                                }}
                                                // Cropper.js options
                                                aspectRatio={2 / 3}
                                                guides={false}
                                                // crop={onCrop}
                                                ref={cropperRef}
                                                responsive={true}
                                                viewMode={0}
                                                background={false}
                                            />
                                        ) : (
                                            <Box
                                                component={"div"}
                                                // src={selectedImage}
                                                sx={{
                                                    width: { xs: "80vw", md: "30vw" },
                                                    height: { xs: "120vw", md: "45vw" },
                                                    backgroundImage: "url(" + selectedImage + ")",
                                                    backgroundPosition: "center",
                                                    backgroundSize: "cover",
                                                }}
                                            />
                                        )}
                                    </Box>
                                </>
                            )}
                            {selectedImage !== "" && contentData.files.length > 0 && (
                                <>
                                    {!editMode ? (
                                        <Button
                                            onClick={() => {
                                                setEditMode(true);
                                            }}
                                            variant="contained"
                                            sx={{
                                                my: 2,
                                            }}
                                        >
                                            Recortar
                                        </Button>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                my: 2,
                                            }}
                                        >
                                            <Button
                                                onClick={() => {
                                                    setEditMode(false);
                                                }}
                                                variant="contained"
                                                sx={{
                                                    mx: 1,
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button
                                                sx={{
                                                    mx: 1,
                                                }}
                                                onClick={() => {
                                                    setEditMode(false);
                                                    const cropper = cropperRef.current?.cropper;
                                                    const newContentDataFiles = contentData.files.map(
                                                        (file) => {
                                                            console.log(file === selectedImage);
                                                            if (
                                                                file === selectedImage &&
                                                                cropper !== undefined
                                                            ) {
                                                                return cropper.getCroppedCanvas().toDataURL();
                                                            } else {
                                                                return file;
                                                            }
                                                        }
                                                    );
                                                    setContentData({
                                                        ...contentData,
                                                        files: newContentDataFiles,
                                                    });
                                                    if (cropper !== undefined) {
                                                        setSelectedImage(
                                                            cropper.getCroppedCanvas().toDataURL()
                                                        );
                                                    }
                                                }}
                                                variant="contained"
                                            >
                                                finalizar
                                            </Button>
                                        </Box>
                                    )}
                                </>
                            )}
                            <Box
                                sx={{
                                    width: "90%",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    mt: 2,
                                    flexWrap: "wrap",
                                }}
                            >
                                {selectedImage !== "" &&
                                    contentData.files.length > 0 &&
                                    contentData.files.length < 7 && (
                                        <Box
                                            sx={{
                                                width: { xs: "30vw", md: "10vw" },
                                                height: { xs: "45vw", md: "15vw" },
                                                border: "0.1vw solid #ADADAD",
                                                borderRadius: 5,
                                                m: 2,
                                                bgcolor: "white",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                overflow: "hidden",
                                            }}
                                            onClick={() => {
                                                addToImage();
                                            }}
                                        >
                                            <AddCircleOutlineIcon
                                                sx={{
                                                    width: { xs: "10vw", md: "5vw" },
                                                    height: { xs: "10vw", md: "5vw" },
                                                    color: "#ADADAD",
                                                    mb: 2,
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    color: "#ADADAD",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Seleccionar imagen
                                            </Typography>
                                        </Box>
                                    )}
                                {contentData.files.length > 0 &&
                                    contentData.files.map((file, index) => (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                position: "relative",
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => handleImageRemove(index)}
                                                aria-label="Remove"
                                                sx={{
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 0,
                                                    m: 2,
                                                }}
                                                color="primary"
                                            >
                                                <RemoveCircleOutlineIcon />
                                            </IconButton>
                                            <Box
                                                component={"div"}
                                                onClick={() => {
                                                    setSelectedImage(file);
                                                }}
                                                sx={{
                                                    width: { xs: "30vw", md: "10vw" },
                                                    height: { xs: "45vw", md: "15vw" },
                                                    border: "0.1vw solid #ADADAD",
                                                    borderRadius: 5,
                                                    m: 2,
                                                    bgcolor: "white",
                                                    backgroundImage: "url(" + file + ")",
                                                    backgroundPosition: "center",
                                                    backgroundSize: "cover",
                                                }}
                                            ></Box>
                                        </Box>
                                    ))}
                            </Box>
                        </Box>

                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: 2,
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    width: "90vw",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: "#8B4DBA",
                                        width: contentData.cardtags.length > 0 ? 35 : 30,
                                        height: 30,
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        m: 1,
                                    }}
                                >
                                    <Typography
                                        color={"white"}
                                        sx={{
                                            fontSize: 10,
                                        }}
                                    >
                                        {contentData.cardtags.length}
                                    </Typography>
                                </Box>
                                {contentData.cardtags && contentData.cardtags.length > 0 && (
                                    <TagSlider
                                        tags={contentData.cardtags}
                                        slides={window.innerWidth < 570 ? 1 : 2}
                                        deleteTag={deleteTag}
                                    />
                                )}
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <TextField
                                    fullWidth
                                    label="Añade tu tag"
                                    name="tag"
                                    id="selecterTags"
                                    type="text"
                                    value={currentInputTagText}
                                    onChange={(e) => setCurrentInputTagText(e.target.value)}
                                    autoComplete="off"
                                    error={!!errors.cardtags}
                                    helperText={!!errors.cardtags && errors.cardtags}
                                    sx={{
                                        bgcolor: "white",
                                        width: { xs: "68vw", md: "30vw" },
                                    }}
                                />
                                <IconButton
                                    onClick={() => addTagList(currentInputTagText)}
                                    sx={{ width: 50, height: 50 }}
                                    aria-label="add"
                                >
                                    <AddCircleOutlineIcon sx={{ width: 50, height: 50 }} />
                                </IconButton>
                            </Box>
                            <Box
                                component={"form"}
                                sx={{ mt: 2 }}
                                autoComplete="off"
                                noValidate
                            >
                                <FormControl
                                    variant="outlined"
                                    required={true}
                                    sx={{
                                        width: "100%",
                                        my: 2,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <OutlinedInput
                                        id="title"
                                        type="text"
                                        name="title"
                                        size="medium"
                                        error={!!errors.title}
                                        value={contentData.title}
                                        label={"Nombre de la publicación"}
                                        onChange={(e) =>
                                            setContentData({ ...contentData, title: e.target.value })
                                        }
                                        sx={{
                                            bgcolor: "white",
                                            width: { xs: "80vw", md: "30vw" },
                                        }}
                                    />
                                    <FormHelperText sx={{ color: "red" }} id="my-helper-text">
                                        {!!errors.title && errors.title}
                                    </FormHelperText>
                                    <InputLabel htmlFor="fistName">
                                        Nombre de la publicación
                                    </InputLabel>
                                </FormControl>

                                <FormControl
                                    variant="outlined"
                                    required={true}
                                    sx={{ width: "100%", mb: 2 }}
                                >
                                    <InputLabel htmlFor="fistName">Descripción</InputLabel>
                                    <OutlinedInput
                                        id="description"
                                        type="text"
                                        name="description"
                                        label="Descripcion"
                                        value={contentData.description}
                                        size="medium"
                                        error={!!errors.description}
                                        onChange={(e) =>
                                            setContentData({
                                                ...contentData,
                                                description: e.target.value,
                                            })
                                        }
                                        sx={{
                                            bgcolor: "white",
                                            width: { xs: "80vw", md: "30vw" },
                                        }}
                                    />
                                    <FormHelperText sx={{ color: "red" }} id="my-helper-text">
                                        {!!errors.description && errors.description}
                                    </FormHelperText>
                                </FormControl>
                            </Box>
                        </Box>
                        <Button
                            sx={{
                                mb: 15,
                                width: { xs: "80vw", md: "30vw" },
                            }}
                            onClick={() => createCard()}
                            autoFocus
                            variant="contained"
                        >
                            Crear Publicación
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        width: "100%",
                        height: "100vh",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <CircularProgress color="primary" />
                </Box>
            )}
            <Box
                component={"img"}
                src={Wave1}
                sx={{
                    width: "100%",
                    height: "auto",
                    bottom: -1,
                    position: "fixed",
                    zIndex: 100,
                }}
            />
            <Box
                component={"img"}
                src={Wave2}
                sx={{
                    width: "100%",
                    height: "auto",
                    bottom: -1,
                    position: "fixed",
                    zIndex: 80,
                }}
            />
        </>
    );
};

export default CreateCard;
