import {
    createBrowserRouter,
    RouterProvider,
  } from "react-router-dom";
//Imports views
import Home from "../views/Home";
import Login from "../views/Login";
import Profile from "../views/Profile";
import Chats from "../views/Chats";
import Register from "../views/Register";
import RecoverPassword from "../views/RecoverPassword";
import RegisterStepTwo from "../views/RegisterStepTwo";
import Trending from "../views/Trending";
import Settings from "../views/Settings";
import PublicProfile from "../views/PublicProfile";
// import Create from "../views/Create";
import AuthProvider from "../context/auth/AuthProvider"
import RequireAuth from "../context/auth/RequireAuth";
import Masters from "../views/Masters";
import TagView from "../views/TagView";
import RegisterStepThree from "../views/RegisterStepThree";
import CreateCard from "../views/CreateCard.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider><RequireAuth><Home/></RequireAuth></AuthProvider>,
  },
  {
    path: "/login",
    element: <AuthProvider><Login/></AuthProvider>,
  },
  {
    path: "/register",
    element: <AuthProvider><Register/></AuthProvider>,
  },
  {
    path: "/register/step/2",
    element: <AuthProvider><RegisterStepTwo/></AuthProvider>,
  },
  {
    path: "/register/step/3",
    element: <AuthProvider><RegisterStepThree/></AuthProvider>,
  },
  {
    path: "/chats",
    element: <AuthProvider><RequireAuth><Chats/></RequireAuth></AuthProvider>,
  },
  {
    path: "/trending",
    element: <AuthProvider><RequireAuth><Trending/></RequireAuth></AuthProvider>,
  },
  {
    path: "/settings",
    element: <AuthProvider><RequireAuth><Settings/></RequireAuth></AuthProvider>,
  },
  {
    path: "/profile",
    element: <AuthProvider><RequireAuth><Profile/></RequireAuth></AuthProvider>,
  },
  {
    path: "/create-card",
    element: <AuthProvider><RequireAuth><CreateCard/></RequireAuth></AuthProvider>,
  },
  {
    path: "/public/:id",
    element: <AuthProvider><RequireAuth><PublicProfile/></RequireAuth></AuthProvider>,
  },
  {
    path: "/search/:tag",
    element: <AuthProvider><RequireAuth><TagView/></RequireAuth></AuthProvider>,
  },
  {
    path: "/recover/:id",
    element: <RecoverPassword/>,
  },
  {
    path: "/masters",
    element: <AuthProvider><RequireAuth><Masters/></RequireAuth></AuthProvider>,
  },
]);

const RootRouter = () => {
  return (
    <RouterProvider router={router} />
  )
}
export default RootRouter