import { createContext, useState } from "react"
import es from '../../JSONs/languages/es.json'
import en from '../../JSONs/languages/en.json'

const InitialLang = JSON.parse(localStorage.getItem('hoshii_lang'))
const LangProvider = ({children}) => {
    const [lang, setLang] = useState(InitialLang || 'es')

    const SelectLang = (lang) => {
        setLang(lang)
        localStorage.setItem('hoshii_lang', JSON.stringify(lang))
    }

    const Lang = (lang)=>{
        if(lang === 'es'){
            return es
        }else if(lang === 'en'){
            return en
        }
    }

    const contextValue = {
        lang,
        Lang,
        SelectLang,
        setLang
    }

    return <LangContext.Provider value={contextValue}>
            {children}
        </LangContext.Provider>
}

export const LangContext = createContext()
export default LangProvider