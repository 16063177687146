import * as React from "react";
// import { styled } from '@mui/material/styles';
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
// import CardContent from "@mui/material/CardContent";
// import CardActions from "@mui/material/CardActions";
// import Collapse from '@mui/material/Collapse';
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
// import { red } from "@mui/material/colors";
// import FavoriteIcon from "@mui/icons-material/Favorite";
import { Box, Button,Tooltip } from "@mui/material";

import profileCardShadow from "../../assets/images/profileCardShadow.png";

export function CardProfile(props) {

  // console.log(props.card.images)

  const [cardDate, setCardDate] = React.useState("");

  const [mediaCount, setMediaCount] = React.useState(0);

  const [cardInterface, setCardInterface] = React.useState(true);

  const ChangeMedia = () => {
    if (cardInterface && props.card.images) {
      if (mediaCount < props.card.images.length - 1) {
        setMediaCount(mediaCount + 1);
      } else if (mediaCount === props.card.images.length - 1) {
        setMediaCount(0);
      }
    }
  };

  React.useEffect(() => {
    if (props.card.createdAt) {
      const cardDateEdited = new Date(props.card.createdAt);
      setCardDate(cardDateEdited.toLocaleDateString("en-GB"));
    }
  }, [props.card.createdAt]);

  const openDetailModal = () => {
    props.setOpenDetailCard(true);
    props.OpenCardModal(props.card);
  };

  return (
    <Card
      sx={{
        width: { xs: "100%", md: 320 },
        maxWidth: 320 ,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        borderRadius: 2.5,
        position: "relative",
      }}
    >
      <CardHeader
        avatar={<Avatar src={props.profilePic} aria-label="recipe"></Avatar>}
        title={
          props.card.title.trim().length > 29 ? (
            <Tooltip title={props.card ? props.card.title.trim() : "Titulo"}>
              <Typography>
                {props.card
                  ? props.card.title.substr(0, 29).trim() + "..."
                  : "Titulo"}
              </Typography>
            </Tooltip>
          ) : props.card ? (
            props.card.title.trim()
          ) : (
            "Titulo"
          )
        }
        subheader={cardDate}
        sx={{
          position: "absolute",
          backgroundImage: `url(${profileCardShadow})`,
          width: "100%",
          color: "white",
          backgroundPosition: "center",
          backgroundSize: "cover",
          ".MuiCardHeader-subheader": {
            color: "white",
          },
        }}
      />
        {
          props.card.images.length === 0 && (
            <Box
            sx={{
              width: "95%",
              height: "0.05vw",
              backgroundColor: "white",
              borderRadius: 10,
              position: "absolute",
              top: 65,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          />
          ) 
        }
       <Box
            sx={{
              width: "99%",
              height: "auto",
              display: "flex",
              flexWrap: "no-wrap",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              position: "absolute",
              top: 65,
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            {
              props.card.images && props.card.images.length > 0 &&  props.card.images.map((tag, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    height: 2,
                    bgcolor: index === mediaCount? "#8B4DBA" : "white",
                    borderRadius: 10,
                    mx: 1,
                  }}
                />
              ))
            }
          </Box>
          <Button
          variant="contained"
          onClick={openDetailModal}
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            mb: 1,
          }}>
            Detalles
          </Button>
        <CardMedia
          component="img"
          height={480}
          image={props.card.images.length > 0? props.card.images[mediaCount].url : props.card.cardImg}
          alt="image"
          onClick={() => {
            ChangeMedia();
          }}
        />
    </Card>
  );
}

export default CardProfile;

// <Card sx={{ width: {xs: "100%", md: 320}, boxShadow: "none", border: "solid 0.1vw lightgray" }}>

//   <CardHeader
//     avatar={
//       <Avatar src={props.profilePic} aria-label="recipe">
//       </Avatar>
//     }
//     title={props.card.title.trim().length > 29 ? (<Tooltip title={props.card ? props.card.title.trim() : "Titulo"}><Typography>{props.card ? props.card.title.substr(0, 29).trim() + "..." : "Titulo"}</Typography></Tooltip>) : (props.card ? props.card.title.trim() : "Titulo")}
//     subheader={cardDate}
//   />
//   <Divider/>
//   <CardActionArea onClick={openDetailModal}>
// <CardMedia
//   component="img"
//   height="250"
//   image={props.card ? props.card.cardImg : "https://res.cloudinary.com/dujeh3ms8/image/upload/v1668012395/tokomarket_uploads_company_adds/na4ofxui5ui4mhfo5i4r.jpg"}
//   alt="image"
// />
//   <Divider/>
//   <CardContent>
//     <Typography variant="body2" color="text.secondary" sx={{height: 100, overflowX: "scroll", "&::-webkit-scrollbar" : { display: "none" }}}>
//       {props.card ? props.card.description : "Descripcion"}
//     </Typography>
//   </CardContent>
//   <CardActions disableSpacing>
//       <FavoriteIcon sx={{color: "#ADADAD"}}/>
//       <Typography>{props.card.likes}</Typography>
//   </CardActions>
//   </CardActionArea>
// </Card>
