import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmModal = ({ openConfirmModal, setOpenConfirmModal, unMatchHandler, chatAccountData, token, removeSelectedChat }) => {
    const handleClose = () => {
        setOpenConfirmModal(false);
    };

    const dismatch = async () => {
        await unMatchHandler(chatAccountData._id, token)
        removeSelectedChat()
        handleClose()
    }
    return (
        <Dialog
            open={openConfirmModal}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"¿Estás seguro que deseas deshacer match?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Si confirmas esta acción, se perdera la conexión que tienes con esta persona,¿Deseas continuar?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={dismatch} autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal