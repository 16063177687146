import { createContext, useState } from 'react'

export const NotyContext = createContext()

const InitialNoty = JSON.parse(localStorage.getItem('hoshii_notification'))

export default function NotificationProvider({ children }) {
    const [notification, setNotification] = useState(InitialNoty || []);

    const addNoty = (noty) => {
        // console.log(noty)
        const oldNotification = JSON.parse(localStorage.getItem('hoshii_notification')) || []
        console.log(oldNotification.includes(noty.message._id))
        if(oldNotification.includes(noty.message._id)){
           return
        }else{
            const newNoty = [...oldNotification, noty]
            localStorage.setItem('hoshii_notification', JSON.stringify(newNoty))
            setNotification(newNoty)
        }

    }

    const contextValue = {
        notification,
        addNoty
    }

    return (
        <NotyContext.Provider value={contextValue}>
            {children}
        </NotyContext.Provider>
    )

}