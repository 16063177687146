import * as React from 'react';
// import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
// import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
// import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Box, Divider, Tooltip } from '@mui/material';

const HistoryCard = (props) => {

    const [cardDate, setCardDate] = React.useState("")

    React.useEffect(() => {
  
      if(props.card.createdAt) {
        const cardDateEdited = new Date(props.card.createdAt)
        setCardDate(cardDateEdited.toLocaleDateString('en-GB'))
      }
  
    }, [props.card.createdAt])

  return (
    <Card sx={{ width: {xs: "100%", md: 320}, boxShadow: "none", border: "solid 0.1vw lightgray" }}>
      
    <CardHeader
      avatar={
        <Avatar sx={{ bgcolor: red[500] }} src={props.profilePic} aria-label="recipe">
        </Avatar>
      }
      title={props.card.title.trim().length > 29 ? (<Tooltip title={props.card ? props.card.title.trim() : "Titulo"}><Typography>{props.card ? props.card.title.substr(0, 29).trim() + "..." : "Titulo"}</Typography></Tooltip>) : (props.card ? props.card.title.trim() : "Titulo")}
      subheader={cardDate}
    />
    <Divider/>
    {/* <CardMedia
      component="img"
      sx={{width: 320, height: 320}}
      image={props.card ? props.card.cardImg : "https://res.cloudinary.com/dujeh3ms8/image/upload/v1668012395/tokomarket_uploads_company_adds/na4ofxui5ui4mhfo5i4r.jpg"}
      alt="image"
    /> */}
    <Box 
        sx={{
            backgroundImage: `url(${props.card.cardImg})`,
            width: {xs: "100%", md: 320},
            height: 320,
            backgroundSize: "cover"
        }}
    />
    <Divider/>
    <CardContent>
      <Typography variant="body2" color="text.secondary" sx={{height: 100, overflowX: "scroll", "&::-webkit-scrollbar" : { display: "none" }}}>
        {props.card ? props.card.description : "Descripcion"}
      </Typography>
    </CardContent>
    <CardActions disableSpacing>
        <FavoriteIcon sx={{color: "#ADADAD"}}/>
        <Typography>{props.card.likes}</Typography>
    </CardActions>
  </Card>
  )
}

export default HistoryCard