import { createContext, useState, useMemo } from 'react'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { esES } from '@mui/material/locale';
import { red, green, orange } from '@mui/material/colors';

export const SelectThemeContext = createContext()

export default function SelectThemeModeContext({ children }) {
    const [mode, setMode] = useState('light');
    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    primary: {
                        main: '#8B4DBA'
                    },
                    secondary: {
                        main: '#B480DA'
                    },
                    green: {
                        main: green
                    },
                    orange: {
                        main: orange
                    },
                    danger: {
                        main: red
                    },
                    default: {
                        main: "#ADADAD"
                    },
                    mode,
                }
            }, esES),
        [mode],
    );

    const contextValue = {
        colorMode,
        theme
    }

    return (
        <SelectThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </SelectThemeContext.Provider>
    )
}